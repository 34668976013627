import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { Card, Tooltip } from '@mui/material';
import { getHumanSustainabilityTagFromIndicator } from '../../../services/statistics/statisticsTools';

interface HistoricData {
  month: string;
  year: number;
  result: number | null;
}




// function createData(
//   name:  string,
//   result: number | null,
//   month: string,
//   year:  number,
  
// ) {
//   return { name, year, result };
// }

// const rows = [
//   createData('IFOZ', 159, 'Jan',2024  ),
//   createData('Aderencia', 159, 'Jan',2024),
//   createData('Engajamento',159, 'Jan',2024),

// ];

// export default function BasicTable() {
//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell></TableCell>
//             <TableCell align="center">Jan</TableCell>
//             <TableCell align="center">Fev</TableCell>
//             <TableCell align="center">Mar</TableCell>
//             <TableCell align="center">Abr</TableCell>
//             <TableCell align="center">Mai</TableCell>
//             <TableCell align="center">Jun</TableCell>
//             <TableCell align="center">Jul</TableCell>
//             <TableCell align="center">Ago</TableCell>
//             <TableCell align="center">Set</TableCell>
//             <TableCell align="center">Out</TableCell>
//             <TableCell align="center">Nov</TableCell>
//             <TableCell align="center">Dez</TableCell>
            
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row) => (
//             <TableRow
              
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell component="th" scope="row">
//                 {row.name}
//               </TableCell>
//               <TableCell align="center">{row.name}</TableCell>
//               <TableCell align="center">{row.name}</TableCell>
//               <TableCell align="center">{row.name}</TableCell>
//               <TableCell align="center">{row.name}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

const TableComponent: React.FC<{ ifozHistoric: HistoricData[], ipaHistoric: HistoricData[], iepHistoric: HistoricData[], ifczHistoric: HistoricData[] }> = ({ ifozHistoric, ipaHistoric, iepHistoric, ifczHistoric }) => {
  // Filtrar os últimos 12 meses com resultados não nulos
  const filteredData = ipaHistoric
    .filter(data => data.result !== null)
    .slice(0, 12)
    .reverse();

  // Criar o cabeçalho da tabela com os meses filtrados
  const tableHeader = filteredData.map(data => (
    <TableCell key={`${data.month}-${data.year}`}>{data.month}</TableCell>
  ));

  // Construir o corpo da tabela com os resultados para cada mês
  const tableBody = () => (
    <TableRow>
      {filteredData.map(data => (
        <TableCell sx={{padding: "0px 0px", width: 100}} key={`${data.month}-${data.year}`}>{data?.result?.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%</TableCell>
      ))}
    </TableRow>
  );

  function Ball({ percentage }: { percentage: number | null }) {
    let background;
    if (!percentage) {
      background = '#c9c9c9';
    } else if (percentage >= 0 && percentage < 40) {
      background = 'linear-gradient(315deg, #FD4659 0%, #FEA993 100%)';
    } else if (percentage >= 40 && percentage < 60) {
      background = 'linear-gradient(314deg, #FA7F63 4.99%, #FEE073 100%)';
    } else if (percentage >= 60 && percentage < 70) {
      background = 'linear-gradient(314deg, #FFDB23 2.1%, #FFF676 78.35%)';
    } else if (percentage >= 70 && percentage < 80) {
      background = 'linear-gradient(318deg, #24c58b 16.44%, #96ffa9 85.22%)';
    } else if (percentage >= 80 && percentage <= 100) {
      background = 'linear-gradient(318deg, #186d4c 16.44%, #0cc93c 85.22%)';
    }
  
    return <div style={{ width: '10px', height: '10px', borderRadius: '50%', background }} />;
  }

  const monthCurt:any= {
    'Janeiro': 'Jan',
    'Fevereiro': 'Fev',
    'Março': 'Mar',
    'Abril': 'Abr',
    'Maio': 'Mai',
    'Junho': 'Jun',
    'Julho': 'Jul',
    'Agosto': 'Ago',
    'Setembro': 'Set',
    'Outubro': 'Out',
    'Novembro': 'Nov',
    'Dezembro': 'Dez'
  };
  
  const ifozData = ifozHistoric.map(item => item?.result ? Number(item?.result.toFixed(1)) : null).reverse()
  const monthsToShow = ifozHistoric.map((item: any) => monthCurt[item.month] + "./" + item.year.toString().slice(-2)).reverse()

  const aderenciaData = ipaHistoric.map(item => item?.result ? Number(item?.result.toFixed(1)) : null).reverse()
  const engajamentoData = iepHistoric.map(item => item?.result ? Number(item?.result.toFixed(1)) : null).reverse()
  const fidelizacaoData = ifczHistoric.map(item => item?.result ? Number(item?.result.toFixed(1)) : null).reverse()



  function createData(
      name:  any,
     ) {
       return {name};
     }
     const columns = monthsToShow;
     const rows = [
      { name: 'IFOZ', data:[ifozData] },
      { name: 'Aderência', data:[aderenciaData]},
      { name: 'Engajamento', data:[engajamentoData] },
      { name: 'Fidelização', data:[fidelizacaoData]}
    ];

    return (

      
        <TableContainer component={Card} sx={{boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)', borderRadius:'.7em'}} >
          <Table sx={{ padding:'1em', fontSize: '14px' }} aria-label="simple table">
            <TableHead sx={{padding: '.5em'}}>
              <TableRow>
                <TableCell style={{borderRight: '2px solid #F1F1F1'}}></TableCell>
                {columns.map((month, index) => (
                  <TableCell sx={{
                    fontSize:'18px',
                    '@media (max-width:601px)': {
                      fontSize: '14px', // Reduz a largura para 70% em telas maiores
                    },
                    '@media (min-width: 602px) and (max-width:1440px)': {
                      fontSize: '14px', // Reduz a largura para 70% em telas maiores
                    }
                  }} 
                  key={index} align="center">
                    {month}
                  </TableCell>
                ))}
                
              </TableRow>
            </TableHead>
            <TableBody key={''} >
                 
                
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow key={row.name}>


                  <TableCell 
                    sx={{
                      width: '5%', 
                      fontSize:'18px',
                      '@media (max-width:601px)': {
                        fontSize: '14px', // Reduz a largura para 70% em telas maiores
                      },
                      '@media (min-width: 602px) and (max-width:1440px)': {
                        fontSize: '14px', // Reduz a largura para 70% em telas maiores
                        
                      }
                    }} 
                    style={{borderRight: '2px solid #F1F1F1'}} 
                    component="th" scope="row"
                  >
                    {row.name}
                  </TableCell>

                  {row.data[0].map((item, index) => (
                    <TableCell 
                    sx={{
                      padding:'1em', 
                      fontSize:'18px',
                      '@media (max-width:601px)': {
                        fontSize: '14px', // Reduz a largura para 70% em telas maiores
                      },
                      '@media (min-width: 602px) and (max-width:1440px)': {
                        fontSize: '14px', // Reduz a largura para 70% em telas maiores
                        padding:'0'
                      }
                    }} 
                    
                    key={index} align="center"
                    >
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'.4em', color:'#878B8E'}}>
                      <Tooltip title={item ? getHumanSustainabilityTagFromIndicator(item) : 'Dados insuficientes'} placement='top' arrow enterTouchDelay={0} leaveTouchDelay={2000}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                    offset: [0, -8]
                                }
                              }
                            ]
                          }
                        }}>

                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'.4em', color:'#878B8E', minWidth:'61.73px'}}>
                          {item === null ? '- -' :  item + '%'}
                          
                          <Ball percentage={item} />
                        </div>
                        
                      </Tooltip>
                    </div>
                    
                  </TableCell>
                  ))}

                </TableRow>
              </React.Fragment>
            ))}
               
            </TableBody>
          </Table>
        </TableContainer>
      );













    //   <TableContainer sx={{width: "max-content", marginLeft: "auto", marginRight: "auto", padding: 1}} component={Paper}>
    //      <TableHead>
    //     <TableRow>
    //       <TableCell>Período</TableCell>
    //       <TableCell>Resultado</TableCell>
    //     </TableRow>
    //   </TableHead>
    //   <TableBody>
    //     <TableRow></TableRow>
    //     {tableBody}
    //   </TableBody>
    // </TableContainer>   

};

export default TableComponent;