import styled, {css} from 'styled-components';

interface HeaderContainerProps {
  hasBackground: boolean;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  min-height: 50px;
  flex-shrink: 0;
  background: #191B35;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  position: relative;
  /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05); */

  ${(props) =>
    !props.hasBackground &&
    css`
      /* background-color: transparent; */
    `}
  
  @media (max-width: 359px){
    padding: 1em .5em;
  }
  
  @media (max-width: 1099px){
    display: flex;
    flex-direction: column-reverse;
    gap: 1em;
    text-align: left;
  }

  @media (min-width: 1100px){
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
    text-align: left;
    padding: 1em;
  }
`;

export const ContainerMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: #FFF;
  align-items: center;
  gap: .2em;
  justify-content: center;
  width: 100%;
  @media (min-width: 1101px){
    flex-grow: 3;
    justify-content: center;
    
  }

  @media (min-width: 1844px){
    flex-grow: 3;
    justify-content: center;
    flex-direction: row;
    
  }


  
`;

export const ContainerMessageTextBold = styled.p`
  font-weight: 700;
  margin:0;
`;

export const ContainerMessageText = styled.p`
  font-weight: 400;
  margin:0;
  opacity: .7;
`;


export const ContainerUserInformation = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;


  @media (max-width: 359px){
    justify-content: space-between;
  }

  @media (min-width: 480px){
    gap: 0;
    justify-content: space-evenly;
    
  }

  
  @media (min-width: 1100px){
    display: flex;
    gap: 2em;
    justify-content: flex-end;
    
  }

`;

export const NormalText = styled.span`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  opacity: 0.7;
  margin-right:5px;
  @media (max-width: 694px){
    margin-right:0; 
    white-space: nowrap;
    text-align: left;
    margin: .2em 0;
    font-size: 14px;
  }
  
`;


export const MainTextSecondary = styled.span`
  color: #FFF;
  font-size: 16px;
  @media (max-width: 894px){
    margin-right:0; 
    font-size: 14px;
  }
  
`;

export const BoldText = styled.p`
  font-weight: 700;
  opacity: 1;
  margin-right:3.8em; 
  color: #FFF;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 600px){
   margin-right:0; 

  } 
  @media (max-width: 768px){
    margin: 0;
    font-size: 14px;
  }
`;


export const PersonName = styled.span`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;

  @media (max-width: 359px){
    max-width: 6em;
    font-size: 14px;
  }

  @media (min-width: 360px) and (max-width: 460px){
    max-width: 110px;
    font-size: 14px;
  }


  @media (min-width: 461px) and (max-width: 700px){
    font-size: 14px;
    max-width: 200px;
  }

  
  

`;

export const CompanyName = styled.span`

  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  @media (max-width: 694px){
    font-size: 14px;
  }
  

`;


export const LogoutButton = styled.button`
  display: inline-flex;
  padding: 8.613px 8px;
  justify-content: center;
  align-items: center;
  gap: 4.785px;
  border-radius: 8px;
  border: 0.479px solid  #FFF;
  background-color: transparent; 
  color: #FFF; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 

  &:hover {
    background-color: #FFF; 
    color: #3C4043; 
  }

  &:focus {
    outline: none; 
  }
  @media (max-width: 694px){
    font-size: 14px;
  }

  @media (max-width: 1100px){
    
   
  }
`;


export const LogoutResponsive = styled.div`
  @media (min-width: 1100px){
    display: none;
   
  }

`;


export const LogoutDesktop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: .5em ;
  @media (max-width: 1099px){
    display: none;
   
  }

`;