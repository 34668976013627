export default (): any => {
	let authUser: any = localStorage.getItem('zenbox.verify')
	
	if (authUser) {
		authUser = JSON.parse(authUser)
		return authUser.verifyToken
	}

	return null
}
