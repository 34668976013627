import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import HalfDoughnutChart from './halfDoughnutChart';
import CountUp from 'react-countup';
import { TitlePercentCard } from './styles';
import { StyledCardHeader, StyledCardBody } from '../attentionPoints/styles';
import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
interface FieldProps {
  percentage: number | null,
  title: string;
  tooltipText: string;
  filterTag: string | null;
}

function getClassificationText(percentage:number | null) {
  if (percentage === null) {
    return "- -";
  }

  const roundedPercentage = parseFloat(percentage.toFixed(1));

  if (roundedPercentage > 0 && roundedPercentage < 40) {
    return "Crítico";
  } else if (roundedPercentage >= 40 && roundedPercentage < 60) {
    return "Em risco";
  } else if (roundedPercentage >= 60 && roundedPercentage < 70) {
    return "Razoável";
  } else if (roundedPercentage >= 70 && roundedPercentage < 80) {
    return "Bom";
  } else if (roundedPercentage >= 80 && roundedPercentage <= 100) {
    return "Excelente";
  } else {
    return "- -";
  }
}

function getClassificationColor(percentage: any) {
  if (percentage === null) {
    return "#B9B9B9";
  }

  const roundedPercentage = parseFloat(percentage.toFixed(1));

  if (roundedPercentage > 0 && roundedPercentage < 40) {
    return "#FD4659";
  } else if (roundedPercentage >= 40 && roundedPercentage < 60) {
    return "#FA7F63";
  } else if (roundedPercentage >= 60 && roundedPercentage < 70) {
    return "#D1AF02";
  } else if (roundedPercentage >= 70 && roundedPercentage < 80) {
    return "#88BF36";
  } else if (roundedPercentage >= 80 && roundedPercentage <= 100) {
    return "#67AB00";
  } else {
    return "#B9B9B9";
  }
}

function  getClassificationColorGradient(percentage:any) {
  if (percentage < 60) {
    return "linear-gradient(314deg, #FA7F63 4.99%, #FEE073 100%)";
  } else {
    return "linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)"; 
  }

}



const PercentCard: React.FC<FieldProps> = ({ percentage, title, tooltipText, filterTag   }) => {
  return (
    <StyledCardBody style={{ color: '#ffff' }}>
      <StyledCardHeader style={{borderRadius: '.7em .7em 0 0 '}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>

          <TitlePercentCard>
            {title}{filterTag ? ` (${filterTag})` : ""}
          </TitlePercentCard>

          <div style={{display:'flex', justifyContent: 'flex-end', position: 'absolute', right: '0'}}>
            <Tooltip sx={{fontSize: 80}} enterTouchDelay={0} leaveTouchDelay={2000} title={tooltipText} arrow>
              <IconButton>
                <InfoOutlinedIcon sx={{fontSize: 20, opacity: '.5'}}/>
              </IconButton>
            </Tooltip>
          </div>
         
          

        </div>
        
      </StyledCardHeader>

      <Card.Body style={{background:'#FFF', boxShadow:' 0px 4px 4px 0px rgba(0, 0, 0, 0.05)', borderRadius: '0 0 .7em .7em '}}>


        <div className="ms-auto text-center position-relative"
        style={{border:'none'}}
        >
          <div className="d-flex align-items-center justify-content-center" >
            <HalfDoughnutChart
              color={getClassificationColor(percentage)}
              target={100}
              reached={((percentage) ? percentage : 0)}
            />
          </div>
          <div style={{
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color:  getClassificationColor(percentage),
            fontSize: 28
          }}>
            {((percentage) ? percentage : 0) % 1 !== 0 ?  
            <CountUp
              start={0}
              end={((percentage) ? percentage : 0)}
              duration={2.75}
              suffix="%"
              decimals={1}
              separator=","
            /> :  
            <CountUp
            start={0}
            end={((percentage) ? percentage : 0)}
            duration={2.75}
            suffix="%"
            separator=","
          />}
           


          </div>
          <p style={{ fontSize: 20, color: getClassificationColor(percentage), margin: 0, fontWeight: '600' }}>{getClassificationText(percentage)}</p>
        </div>


      </Card.Body>
    </StyledCardBody>
  );
};

export default PercentCard;
