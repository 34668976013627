import styled from "styled-components";
export {};


export const TitlePercentCard = styled.h5`
    font-weight: 600;
    text-align: center;
    color: #3C4043;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    @media (max-width: 320px){
        font-size: 1em;
    }

    @media (min-width: 321px) and (max-width: 540px){
        max-width: 87px;
        font-size: 1.05em;
    }

    @media (min-width: 1214px){
        font-size: 1.3em;
    
    }     
    

`; 

export const ContainerPercentCard = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr ;


    @media (min-width: 344px) and (max-width: 640px){
        grid-template-columns: 1fr 1fr ;
        gap: 1em;
    }

    @media (min-width: 641px){
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        
    }
    @media (min-width: 1050px){
        grid-template-columns: 1fr 1fr 1fr 1fr;
    
    }     
 
`;