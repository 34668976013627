import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './assets/fonts/font.css'
import * as Sentry from "@sentry/react";

import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);


Sentry.init({
  environment: (process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "development"),
  dsn: "https://ad8f3194354aa2ba1a023c671d6d5b18@o1089700.ingest.sentry.io/4506622160863232",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: (process.env?.REACT_APP_SENTRY_ENVIRONMENT == "production" ? 0.1 : 0.0), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: (process.env?.REACT_APP_SENTRY_ENVIRONMENT == "production" ? 1.0 : 0.0), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});  


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <App />
 
);


