import { AllDimensionsIfozElements, DimensionIfozElement } from '../../interfaces/dimension.interface'
import requestBack from '../config/request-back'


const getEPDimension = async (companyId: any, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-ep/${companyId}/${month}/${year}`,
        vtAuth: true,
    })

    return response
}

const getENDimension = async (companyId: any, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-en/${companyId}/${month}/${year}`,
        vtAuth: true,
    })
    return response
}

const getREDimension = async (companyId: any, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-re/${companyId}/${month}/${year}`,
        vtAuth: true,
    })

    return response
}

const getSIDimension = async (companyId: any, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-si/${companyId}/${month}/${year}`,
        vtAuth: true,
    })

    return response
}


const getREADimension = async (companyId: any, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-rea/${companyId}/${month}/${year}`,
        vtAuth: true,
    })

    return response
}

const getVITDimension = async (companyId: any, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-vit/${companyId}/${month}/${year}`,
        vtAuth: true,
    })

    return response
}

const getDissertations = async (companyId: any, month: string, year: string, page:string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-surveys-dissertations/${companyId}/${month}/${year}/${page}`,
        vtAuth: true,
    })

    return response
}

const getDimensionIFOZ = async (companyId: any, dimensionId: number, month: string, year: string) => {
    let response = await requestBack({
        method: 'GET',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-ifoz/${companyId}/${dimensionId}/${month}/${year}`,
        vtAuth: true,
    })

    return response
}

const getIFOZByParameters =  async (companyId: any, month:string, year:string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifoz-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

const getDimensionIFOZByParameters =  async (companyId: any, dimensionId: number, month:string, year:string, filters?: any): Promise<DimensionIfozElement> => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifoz-dimension-builder`,
		vtAuth: true,
		data: {company_id: companyId, dimension_id: dimensionId, month, year, ...filters},
	})

	return response
}

const getAllDimensionsIFOZByParameters =  async (companyId: any, month:string, year:string, filters?: any): Promise<AllDimensionsIfozElements> => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifoz-all-dimensions-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

const getGroupsAllDimensionsIFOZByParameters =  async (companyId: any, month:string, year:string, groups: string[]): Promise<{[groupId: string]: AllDimensionsIfozElements}> => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-groups-ifoz-all-dimensions-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, groups},
	})

	return response
}

const getDissertationsByParameters = async (companyId: any, month: string, year: string, page:string, filters?: any) => {
    let response = await requestBack({
        method: 'POST',
        url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-survey-dissertations-builder`,
        vtAuth: true,
        data: {company_id: companyId, month, year, page, ...filters},
    })

    return response
}

const getIAPByParameters =  async (companyId: any, month:string, year:string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-iap-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

const getIEPByParameters =  async (companyId: any, month:string, year:string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-iep-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

const getIFCZByParameters =  async (companyId: any, month:string, year:string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifcz-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}



export { getENDimension, getEPDimension, getREADimension, getREDimension, getSIDimension, getVITDimension, getDissertations, getDimensionIFOZ, getIFOZByParameters, getDimensionIFOZByParameters as getIFOZDimensionByParameters, getDissertationsByParameters, getIAPByParameters, getIEPByParameters, getIFCZByParameters, getAllDimensionsIFOZByParameters, getGroupsAllDimensionsIFOZByParameters }
