import styled from 'styled-components';
import Sidebar from './index';
import { FiAlignLeft, FiX } from "react-icons/fi"
import { NavLink } from 'react-router-dom';





export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #3C4043;
  font-size: 'Product Sans';

  /* &:active{
    background: rgba(113, 237, 203, 0.40);
  }

  &:focus{
    background-color: red;
  } */

`
export const StyledNameUser = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 675px){
    display: none;
  }

`;

export const StyledNameUserResponsive = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  @media (min-width: 675px){
    display: none;
  }

`;

export const StyledNameUserParagraph = styled.p`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;

  @media (max-width: 359px){
    max-width: 6em;
    font-size: 14px;
  }

  @media (min-width: 360px) and (max-width: 460px){
    max-width: 250px;
    font-size: 14px;
  }


  @media (min-width: 461px) and (max-width: 700px){
    font-size: 14px;
    max-width: 200px;
  }
`;
export const StyledDividerResponsive = styled.div`
  @media (min-width: 675px){
    display: none;
  }

`;

export const StyledtitleText = styled.h3`
  color: #3C4043;
  font-weight: 700;
  margin-top: .4em;

  @media (max-width: 768px){
    font-size: 1.2em;
    margin-top: .6em;
  }
`;


export const Rectangle = styled.div`
width: 22px;
height: 22px;
transform: rotate(-45deg);
flex-shrink: 0;
border-radius: 5px;
background: var(--Primary-Gradient, linear-gradient(17deg, #6018DC -28.06%, #EEAD92 103.25%));
  @media (max-width: 768px){
    width: 30px;
    height: 30px;
  }
`;

export const SidebarContainer = styled.div`
  display: block;


  @media (max-width: 768px){
    display: none;
  }
`;

export const CloseIcon = styled(FiX)`
  color: #fff;
  cursor: pointer;
  z-index: 1000; /* Z-index maior para o ícone de fechar */
`;

export const SidebarResponsive = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  z-index: 999;
  transition: left 0.3s ease;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 100vh;
  width: 80%;
  background-color: #2a2a48;
  overflow-x: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* Adiciona uma sombra ao redor do menu */

  @media (min-width: 769px){
    display: none;
  }
`;
