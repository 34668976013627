import { endOfMonth, getDate } from "date-fns"
import { useEffect, useState } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import CustomCard from "../../components/customCard"
import Header from "../../components/header"
import { BackButtonSignUp, ContainerSignUp, ContainerSignUpButtons, NextButtonSignUp, StyledInputSignUp, StyledSignUpText } from "../../components/renderField/styles"
import { useAuth } from "../../context/AuthContext"
import { useToast } from "../../context/Toast"
import { getCompanyId } from "../../services/company/company"
import { getUserByEmail, newUser, resendConfirmationCode, sendSurveys, signup, verifyCodeEmail } from "../../services/user/user"
import { CardEmployee } from "../welcome/styles"

const SignUpWebSummmitRio24: React.FC = () => {
    const [savedValue, setSavedValue] = useState('')
    const { showToast } = useToast()
    const { user, saveDataVerifyOnStorage, saveDataSignUpOnStorage } = useAuth()
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    useEffect(() => {

    }, []);

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const onSubmit = async () => {
        try {
            if (user.verifyToken) {

                if (!savedValue) return (
                    showToast('Você precisa informar um e-mail.', 'error')
                )

                if (!validEmailRegex.test(savedValue.trim())) return (
                    showToast('Informe um e-mail válido!', 'error')
                )

                try {
                    setLoading(true)

                    let result = null
                    try {
                        result = await getUserByEmail(savedValue)
                        if (result.id) {
                            setLoading(true)
                            try {
                                await sendSurveys({  // Envia link para segundo ou posterior acesso de usuários ativos
                                    address: savedValue.trim()
                                })
                                showToast('Link de acesso enviado :)\n\nFoi enviado no seu e-mail um link especial para que possa acessar sua reflexão.', 'success', 15000)
                            } catch (error: any) {
                                if (error.response) {
                                    if (error.response.status === 403 && error.response.data && error.response.data.error) {  // TODO: Implementar verificação se o e-mail existe com base no retorno do back
                                        switch (error.response.data.error) {
                                            case "Erro ao gerar token": // Nesta condição estamos considerando o usuário que está convidado porém ainda não ativou a conta
                                                try {
                                                    let email = savedValue.trim();
                                                    await resendConfirmationCode(email); // TODO: Implementar verificação se um novo convite foi enviado ou não.
                                                    showToast('📩 Você já tem uma conta!\n\Enviamos para a sua caixa de e-mail um novo convite para que possa continuar a sua jornada. Procure por "Zenbox Life".\n\nCaso não o encontre, primeiro certifique-se de que informou o endereço corretamente.', 'success', 40000)
                                                    break;
                                                } catch(e: any) {
                                                    throw e;
                                                }

                                            default:
                                                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                                                setLoading(false)
                                                throw error;
                                        }
                                    }
                                } else {
                                    throw (error)
                                }
                            }
                        } else {
                            throw { error: "Ocorreu um problema ao procurar um usuário válido com esse e-mail" }
                        }
                    } catch (error: any) {
                        if (error?.response?.data?.error == "Usuário não encontrado") {
                            try {
                                const company = user.user?.company_id
                                if (!company)
                                    throw new Error("company_not_found")

                                let dataSend = {
                                    email: savedValue,
                                    password: "Zenbox@life2024"
                                }
                                try {
                                    await signup(dataSend)
                                } catch (e) {
                                    throw (e)
                                }

                                const rawUserData = {
                                    first_name: "",
                                    last_name: "",
                                    email: savedValue,
                                    country: "Brasil",
                                    newsletter_sub: 'S',
                                    role: 3,
                                    sex: 0,
                                    company: company
                                }
                                let userCreated = await newUser(rawUserData);

                                const dataToSave = {
                                    id: userCreated?.id,
                                    email: userCreated?.email,
                                    status: userCreated?.status,
                                    first_name: userCreated?.first_name,
                                    last_name: userCreated?.last_name,
                                    company_id: userCreated?.company?.Item?.id,
                                    company_name: userCreated?.company?.Item?.name_company,
                                    role: userCreated?.role,
                                }
                                saveDataSignUpOnStorage(dataToSave);
                                showToast('Tudo certo!\n\nFinalize seu cadastro preenchendo os campos abaixo.', 'info', 10000)
                                navigate('/register')

                            } catch (e) {
                                throw { error: "Falha ao cadastrar usuário" }
                            }
                        } else {
                            console.warn(error)
                            showToast('Tivemos um problema e não conseguimos consultar o seu e-mail...\n\nVocê poderia tentar novamente por gentileza?', 'error', 15000)
                            throw error;
                        }
                    }

                    setLoading(false)
                } catch (error) {
                    showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                    setLoading(false)
                    throw error
                }

                setLoading(false)
            }
        } catch (e: any) {
            if (e === 'error_user_email') {
                try {
                    let email = savedValue.trim();
                    await resendConfirmationCode(email); // TODO: Implementar verificação se um novo convite foi enviado ou não.
                    showToast('📩 Novo convite enviado!\n\nVocê deve ter recebido em sua caixa de e-mail um novo convite. Procure por "Zenbox Life".\n\nCaso não o encontre, primeiro certifique-se de que informou o endereço corretamente. Se ainda assim não tiver recebido, contate o gestor de felicidade da sua organização.', 'success', 40000)

                } catch (err: any) {
                    if (err?.response && err?.response?.status
                        && err?.response?.data && err?.response?.data?.message) {
                        switch (err.response.data.message) {
                            case "Resend confirmation code failed":
                                showToast('Aguarde um pouco...\n\nEspere alguns minutos antes de tentar solicitar um novo convite novamente.', 'error', 15000);
                                showToast('Experimente tentar acessar pelo último convite que recebeu!\n\nProcure por "Zenbox Life" na sua caixa de e-mails.', 'info', 20000);
                                setLoading(false);
                                break;

                            default:
                                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela.', 'error', 30000);
                                setLoading(false);
                                throw err;
                        }
                    } else {
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela.', 'error', 30000);
                        setLoading(false)
                        throw err;
                    }
                }
            }
            else if (e?.message === 'error_user_register') {
                console.warn(e, { message: 'error_user_register - Usuário já verificou o e-mail - Redirecionando para término do cadastro' });
                const result = e.result
                const dataToSend = {
                    verifyToken: result.token,
                    user: {
                        email: savedValue.trim()
                    }
                }
                saveDataVerifyOnStorage(dataToSend)
                const user = await getUserByEmail(savedValue.trim())
                if (user.status === 2) {
                    showToast('Finalize seu cadastro preenchendo todos os campos do formulário.', 'info', 15000)
                    navigate('/register')
                }


                const dataToSave = {
                    id: user?.id,
                    email: user?.email,
                    status: user?.status,
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    company_id: user?.company?.Item?.id,
                    company_name: user?.company?.Item?.name_company,
                    role: user?.role,

                }
                saveDataSignUpOnStorage(dataToSave)

                const company = await getCompanyId(user?.company?.Item?.id)
                if (!company.Item.surveys_all_month) {
                    const today = new Date()
                    const day = getDate(today)
                    const lastDayOfMonth = getDate(endOfMonth(today))

                    if (!(day >= 20 && day <= lastDayOfMonth)) {
                        showToast('Estamos fora do periodo de reflexão :(\n\nPara responder a reflexão do mês, você deve acessar durante o período do dia 20 até o último dia do mês.', 'error', 20000)
                    } else {
                        navigate('/questions')
                    }
                } else {
                    navigate('/questions')
                }
            }
            else {
                showToast('Como receber um novo convite:\n\nAbra em uma nova janela o endereço "web.zenbox.life", informe seu e-mail e prossiga para receber um novo convite.', 'info', 60000)
            }
            setLoading(false)
        }
    }

    return (
        <>
            <Header />
            <Container fluid className="d-flex h-100 mt-5 mb-2">
                <Row className="justify-content-center align-self-center w-100">
                    <Col md={9} lg={7}>
                        <CardEmployee>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{
                                    minHeight: '300px',

                                }}>
                                    <Spinner style={{ color: '#c9c9c9' }}></Spinner>
                                </div>
                            ) : (
                                <ContainerSignUp>
                                    <div className="d-flex justify-content-center flex-column ">
                                        <StyledSignUpText>{'Primeiro vamos precisar do seu e-mail corporativo:'}</StyledSignUpText>
                                        <div className="d-flex justify-content-center" style={{
                                            width: '100%',
                                            flexDirection: 'column',
                                        }}>
                                            <StyledInputSignUp
                                                id={`email-singup`}
                                                type='email'
                                                value={savedValue ? savedValue : ''}
                                                rows="1"
                                                onChange={(e) => setSavedValue(e.target.value?.toLowerCase())}
                                            />

                                        </div>
                                    </div>
                                    <ContainerSignUpButtons>
                                        <NextButtonSignUp onClick={onSubmit}>Começar</NextButtonSignUp>
                                    </ContainerSignUpButtons>
                                </ContainerSignUp>
                            )}
                        </CardEmployee>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SignUpWebSummmitRio24
