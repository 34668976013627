import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';
export{}

interface IDrawerContextData {
  isDrawerOpen: boolean;
  toggleDrawerOpen: () => void;
}

const DrawerContext = createContext<IDrawerContextData | undefined>(undefined);

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider');
  }
  return context;
};

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(oldDrawerOpen => !oldDrawerOpen);
  }, []);

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, toggleDrawerOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};













// import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

// interface IDrawerContextData {
//   isDrawerOpen: boolean;
//   toggleDrawerOpen: () => void;
//   children: ReactNode
// }

// const DrawerContext = createContext({} as IDrawerContextData);

// export const useDrawerContext = () => {
//   return useContext(DrawerContext);
// };

// export const DrawerProvider: React.FC<IDrawerContextData> = ({ children }) => {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   const toggleDrawerOpen = useCallback(() => {
//     setIsDrawerOpen(oldDrawerOpen => !oldDrawerOpen);
//   }, []);

//   return (
//     <DrawerContext.Provider value={{ isDrawerOpen, toggleDrawerOpen }}>
//       {children}
//     </DrawerContext.Provider>
//   );
// };