import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import { Gradient } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { CardDimension, CardDimensionContent, ContainerCardDimension, CardDimensionContentResponsive, LineContainer } from './styles';
import './insights-line-chart.css'
import { DimensionID } from '../../../enums/dimension';
import { FormattedDimensionResults } from '../../../interfaces/dimension.interface';
import { getDataFromFilteredSeries, getQuestionIFOZArrayFromDimension } from '../../../services/statistics/statisticsTools';
import { LineChartSeriesInterface } from '../../../interfaces/chartSeries.interface';


type LineChartProps = {
  chartId: string,
  heightSize: string,
  heightSizeResponsive: string,
  topPosition: string,
  topPositionResponsive: string,
  heightSizefiltered?: string,
  topPositionfiltered?: string,
  generalEpData: FormattedDimensionResults | null,
  generalEngData: FormattedDimensionResults | null,
  generalRelData: FormattedDimensionResults | null,
  generalSigData: FormattedDimensionResults | null,
  generalReaData: FormattedDimensionResults | null,
  generalVitData: FormattedDimensionResults | null,
  filteredEpData?: FormattedDimensionResults | null,
  filteredEngData?: FormattedDimensionResults | null,
  filteredRelData?: FormattedDimensionResults | null,
  filteredSigData?: FormattedDimensionResults | null,
  filteredReaData?: FormattedDimensionResults | null,
  filteredVitData?: FormattedDimensionResults | null,
  genderSeries?: LineChartSeriesInterface[],
  ageRangesSeries?: LineChartSeriesInterface[],
  groupsSeries?: LineChartSeriesInterface[],
  appendedSeries?: LineChartSeriesInterface[]
  monthSeries?: LineChartSeriesInterface[]
};


const MonthReportLineChart = ({ 
  heightSize,
  topPosition,
  heightSizeResponsive,
  topPositionResponsive,
  heightSizefiltered,
  topPositionfiltered,
  chartId, 
  generalEpData,
  generalEngData,
  generalRelData,
  generalSigData,
  generalReaData,
  generalVitData,
  filteredEpData,
  filteredEngData,
  filteredRelData,
  filteredSigData,
  filteredReaData,
  filteredVitData,
  genderSeries,
  ageRangesSeries,
  groupsSeries,
  monthSeries,
  appendedSeries 
}: LineChartProps) => {

  interface Annotation {
    x: string;
    strokeDashArray: number;
    borderColor: string;
    offsetX: number;
  }

  interface AnnotationOptions {
    annotations: { xaxis: Annotation[] };
  }

  interface Breakpoint {
    breakpoint: number;
    options: AnnotationOptions;
  }

  function getResponsiveBreakpoints(): Breakpoint[] {
    const breakpoints: Breakpoint[] = [];

    const breakpointsData = [
      { breakpoint: 800, offsetX: 15 },
      { breakpoint: 1000, offsetX: 15 },
      { breakpoint: 1100, offsetX: 18 },
      { breakpoint: 1300, offsetX: 20 },
      { breakpoint: 1400, offsetX: 22 },
      { breakpoint: 1550, offsetX: 24 },
      { breakpoint: 1700, offsetX: 28 }
    ];

    const annotationTemplate: Annotation = {
      x: 'D',
      strokeDashArray: 0,
      borderColor: '#808086',
      offsetX: 0
    };

    for (const data of breakpointsData) {
      const annotations: Annotation[] = [];
      for (let i = 1; i <= 5; i++) {
        const annotation = { ...annotationTemplate };
        annotation.x += `${i}.4`;
        annotation.offsetX = data.offsetX;
        annotations.push(annotation);
      }
      breakpoints.push({
        breakpoint: data.breakpoint,
        options: {
          annotations: { xaxis: annotations }
        }
      });
    }

    return breakpoints;
  }

  function getSeriesName(value: any) {
    const dimension = value.split('.')[0]; // pega a primeira parte do valor (antes do ponto)

    switch (dimension) {
      case 'D1':
        return 'Emoções Positivas';
      case 'D2':
        return 'Engajamento';
      case 'D3':
        return 'Relacionamento';
      case 'D4':
        return 'Significado';
      case 'D5':
        return 'Realização';
      case 'D6':
        return 'Vitalidade';

      default:
        return '';
    }
  }

  function getGeneralData(): number[] {
    let epValues: number[] = getQuestionIFOZArrayFromDimension(generalEpData?.questionsResults ?? []);
    let enValues: number[] = getQuestionIFOZArrayFromDimension(generalEngData?.questionsResults ?? []);
    let relValues: number[] = getQuestionIFOZArrayFromDimension(generalRelData?.questionsResults ?? []);
    let sigValues: number[] = getQuestionIFOZArrayFromDimension(generalSigData?.questionsResults ?? []);
    let reaValues: number[] = getQuestionIFOZArrayFromDimension(generalReaData?.questionsResults ?? []);
    let vitValues: number[] = getQuestionIFOZArrayFromDimension(generalVitData?.questionsResults ?? []);

    let dimensionValues: number[] = [...epValues, ...enValues, ...relValues, ...sigValues, ...reaValues, ...vitValues];

    return dimensionValues;
  }

  function getFilteredData(): number[] {
    let epValues: number[] = getQuestionIFOZArrayFromDimension(filteredEpData?.questionsResults ?? []);
    let enValues: number[] = getQuestionIFOZArrayFromDimension(filteredEngData?.questionsResults ?? []);
    let relValues: number[] = getQuestionIFOZArrayFromDimension(filteredRelData?.questionsResults ?? []);
    let sigValues: number[] = getQuestionIFOZArrayFromDimension(filteredSigData?.questionsResults ?? []);
    let reaValues: number[] = getQuestionIFOZArrayFromDimension(filteredReaData?.questionsResults ?? []);
    let vitValues: number[] = getQuestionIFOZArrayFromDimension(filteredVitData?.questionsResults ?? []);

    let dimensionValues: number[] = [...epValues, ...enValues, ...relValues, ...sigValues, ...reaValues, ...vitValues];

    return dimensionValues;
  }



  useEffect(() => {

    const values = ['D1.1', 'D1.2', 'D1.3', 'D1.4', 'D2.1', 'D2.2', 'D2.3', 'D2.4', 'D3.1', 'D3.2', 'D3.3', 'D3.4', 'D4.1', 'D4.2', 'D4.3', 'D4.4', 'D5.1', 'D5.2', 'D5.3', 'D5.4', 'D6.1', 'D6.2', 'D6.3', 'D6.4'];
    // const data = values.map(getData);

    const generalData = getGeneralData()

    const filteredData = getFilteredData()

    let aditionalSeries = appendedSeries?.map(series => { return { name: [series.label], data: getDataFromFilteredSeries(series) } }) ?? []
    let formattedGenderSeries = genderSeries?.map(series => { return { name: [series.label], data: getDataFromFilteredSeries(series) } }) ?? []
    let formattedAgeRangesSeries = ageRangesSeries?.map(series => { return { name: [series.label], data: getDataFromFilteredSeries(series) } }) ?? []
    let formattedGroupsSeries = groupsSeries?.map(series => { return { name: [series.label], data: getDataFromFilteredSeries(series) } }) ?? []
      

    // const seriesName = values.map(getSeriesName);
    // const uniqueSeriesNames = seriesName.filter((name, index) => seriesName.indexOf(name) === index);


    
    let chartSeries = [
      {
        name: ['IFOZ Geral'],
        data: generalData,
      },
      ...aditionalSeries,
      ...formattedGenderSeries,
      ...formattedAgeRangesSeries,
      ...formattedGroupsSeries
    ]

    if(filteredData.length > 0) {
      chartSeries.push({ name: ['Seu filtro'], data: filteredData })
    }

    let options = {
      chart: {
        height: 400,
        type: "line",
        stacked: false,

        toolbar: {
          show: false,
        }
      },
      dataLabels: {
        enabled: false
      },

      series: chartSeries,

      markers: {
        size: [6], // Tamanho dos marcadores de ponto
        colors: [
          '#2B81FF', '#fc7100', '#2c773a', '#A996FF', '#FF33E6', '#E6FF33', 
          '#633604', '#860070', '#99FF33', '#14518d', '#fc0000', '#33FFC5',
          '#FFD700', '#898a88', '#8B0000', '#00FF00', '#00CED1', '#FF1493',
          '#4B0082', '#7CFC00', '#8A2BE2', '#00FA9A', '#DC143C', '#00BFFF',
          '#ADFF2F', '#FF6347', '#7FFF00', '#FF4500', '#9932CC', '#40E0D0',
          '#C71585', '#FF00FF', '#8B4513', '#4682B4', '#D2691E', '#6495ED',
          '#556B2F', '#7B68EE', '#FFB6C1', '#8FBC8F', '#483D8B', '#B22222',
          '#FF69B4', '#8A2BE2', '#5F9EA0', '#DDA0DD', '#FA8072', '#BDB76B',
          '#F4A460', '#7FFFD4', '#66CDAA', '#CD5C5C', '#E9967A', '#32CD32',
          '#DA70D6', '#98FB98', '#AFEEEE', '#DB7093', '#DEB887', '#D2691E',
          '#20B2AA', '#48D1CC', '#B0E0E6', '#ADD8E6', '#87CEEB', '#87CEFA',
          '#4682B4', '#5F9EA0', '#B0C4DE', '#778899', '#708090', '#6A5ACD',
          '#6B8E23', '#8FBC8F', '#9370DB', '#9400D3', '#BA55D3', '#470047',
          '#D8BFD8', '#A52A2A', '#A0522D', '#8B008B', '#556B2F', '#2E8B57',
          '#3CB371', '#2F4F4F', '#191970', '#8B0000', '#4682B4', '#008080',
          '#808000', '#800000', '#008B8B', '#8B4513', '#B8860B', '#BDB76B'
        ],
        
        strokeColors: '#CACBDA', // Cor da borda dos marcadores
        strokeWidth: 2, // Largura da borda dos marcadores
        hover: {
          size: 8, // Tamanho dos marcadores quando o mouse passa sobre eles
        },

      },
      colors: [
        '#2B81FF', '#fc7100', '#2c773a', '#A996FF', '#FF33E6', '#E6FF33', 
        '#633604', '#860070', '#99FF33', '#14518d', '#fc0000', '#33FFC5',
        '#FFD700', '#898a88', '#8B0000', '#00FF00', '#00CED1', '#FF1493',
        '#4B0082', '#7CFC00', '#8A2BE2', '#00FA9A', '#DC143C', '#00BFFF',
        '#ADFF2F', '#FF6347', '#7FFF00', '#FF4500', '#9932CC', '#40E0D0',
        '#C71585', '#FF00FF', '#8B4513', '#4682B4', '#D2691E', '#6495ED',
        '#556B2F', '#7B68EE', '#FFB6C1', '#8FBC8F', '#483D8B', '#B22222',
        '#FF69B4', '#8A2BE2', '#5F9EA0', '#DDA0DD', '#FA8072', '#BDB76B',
        '#F4A460', '#7FFFD4', '#66CDAA', '#CD5C5C', '#E9967A', '#32CD32',
        '#DA70D6', '#98FB98', '#AFEEEE', '#DB7093', '#DEB887', '#D2691E',
        '#20B2AA', '#48D1CC', '#B0E0E6', '#ADD8E6', '#87CEEB', '#87CEFA',
        '#4682B4', '#5F9EA0', '#B0C4DE', '#778899', '#708090', '#6A5ACD',
        '#6B8E23', '#8FBC8F', '#9370DB', '#9400D3', '#BA55D3', '#470047',
        '#D8BFD8', '#A52A2A', '#A0522D', '#8B008B', '#556B2F', '#2E8B57',
        '#3CB371', '#2F4F4F', '#191970', '#8B0000', '#4682B4', '#008080',
        '#808000', '#800000', '#008B8B', '#8B4513', '#B8860B', '#BDB76B'
      ],
      
      
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shade: 'dark',
      //     type: 'horizontal',
      //     shadeIntensity: 0.5,
      //     gradientToColors: undefined, // optional, if you want to define a gradient color
      //     inverseColors: true,
      //     opacityFrom: 1,
      //     opacityTo: 1,
      //     stops: [0, 50, 100],
      //     colorStops: []
      //   }
      // },











      grid: {
        show: true,

        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true,
          }
        },
        row: {
          colors: ['rgba(241, 241, 241, 1)'],
          opacity: 0.5
        },
        column: {
          colors: ['rgba(241, 241, 241, 1)'],
          opacity: 0.5
        },
      },


      responsive: getResponsiveBreakpoints(),
      stroke: {
        width: 4,
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
          background: 'red'
        }
      },
      xaxis: {
        categories: ['D1.1', 'D1.2', 'D1.3', 'D1.4', 'D2.1', 'D2.2', 'D2.3', 'D2.4', 'D3.1', 'D3.2', 'D3.3', 'D3.4', 'D4.1', 'D4.2', 'D4.3', 'D4.4', 'D5.1', 'D5.2', 'D5.3', 'D5.4', 'D6.1', 'D6.2', 'D6.3', 'D6.4'],
        labels: {
          rotate: -45, // Rotaciona os rótulos para torná-los legíveis
          style: {
            fontSize: '12px'
          },
        },
        tickPlacement: 'between',
      },
      annotations: {
        yaxis: [
          {
            y: 70,
            strokeDashArray: 0,
            borderColor: '#8fff1d',
            borderWidth: 4,
            mouseEnter: {},
            label: {
              borderColor: '#8fff1d',
              style: {
                color: '#3C4043',
                background: '#8fff1d',
                fontSize: '12px',
              },
              text: 'Bom',
            }
          }
        ],



        xaxis: [
          {
            x: 'D1.4',
            strokeDashArray: 0,
            borderColor: '#808086',
            offsetX: 34

          },
          {
            x: 'D2.4',
            strokeDashArray: 0,
            borderColor: '#808086',
            offsetX: 34

          },
          {
            x: 'D3.4',
            strokeDashArray: 0,
            borderColor: '#808086',
            offsetX: 34

          },
          {
            x: 'D4.4',
            strokeDashArray: 0,
            borderColor: '#808086',
            offsetX: 34

          },
          {
            x: 'D5.4',
            strokeDashArray: 0,
            borderColor: '#808086',
            offsetX: 34

          },
        ]
      },

      yaxis: [
        {
          min: 0,
          max: 100,
          tickAmount: 4,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (val: number) {
              return (val || val == 0) ? `${val}%` : `Dados insuficientes`; // Isso adicionará um sinal de porcentagem ao final do valor
            },

            style: {
              colors: "#878B8E"
            }
          },
          title: {
            style: {
              color: "#FF1654"
            }
          }
        },

      ],
      tooltip: {
        shared: true,
        zIndex: 1000,
        x: {
          show: true
        }
      },
      legend: {
        show: true,
        position: 'top',
        height: 60,
        },

      zoom:{
        enabled: true
      }
    };

    const isSmallScreen = window.matchMedia("(max-width: 1379px)").matches;

    const chart = new ApexCharts(document.getElementById(chartId), options);
    chart.render();

    const gradientAxis = document.getElementById(`${chartId}-gradient-axis`);
    if (gradientAxis) {
      gradientAxis.style.background = "linear-gradient(to bottom, #00920F 0%, #00FF19 25%, #ECFF20 35%, #F1C118 45%, #F00 70%, #F00 100%)";
      gradientAxis.style.width = "5px"; // Ajuste conforme necessário
      gradientAxis.style.height = options.series.length > 1 ? `${heightSizeResponsive}` : `${heightSize}`; 
      gradientAxis.style.position = "absolute";
      gradientAxis.style.top = options.series.length > 1 ? `${topPositionResponsive}` : `${topPosition}`;
      gradientAxis.style.left = "50px";
      gradientAxis.style.zIndex = '1000'
    }

    // Retorne uma função de limpeza no useEffect para destruir o gráfico ao desmontar o componente
    return () => {
      chart.destroy();
      if (gradientAxis) {
        gradientAxis.style.background = "none"; // Limpa o gradiente ao desmontar o componente
      }
    };

  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div id={chartId} />
      <div id={`${chartId}-gradient-axis`} />

      <ContainerCardDimension>
        <CardDimension>
          <CardDimensionContentResponsive>Emo. Pos</CardDimensionContentResponsive>
          <CardDimensionContent>Emoções positivas</CardDimensionContent>
        </CardDimension>

        <CardDimension>
          <CardDimensionContentResponsive>Engajam</CardDimensionContentResponsive>
          <CardDimensionContent>Engajamento</CardDimensionContent>
        </CardDimension>

        <CardDimension>
          <CardDimensionContentResponsive>Relacion</CardDimensionContentResponsive>
          <CardDimensionContent>Relacionamento</CardDimensionContent>
        </CardDimension>

        <CardDimension>
          <CardDimensionContentResponsive>Signif</CardDimensionContentResponsive>
          <CardDimensionContent>Significado</CardDimensionContent>
        </CardDimension>

        <CardDimension>
          <CardDimensionContentResponsive>Realiz</CardDimensionContentResponsive>
          <CardDimensionContent>Realização</CardDimensionContent>
        </CardDimension>

        <CardDimension>
          <CardDimensionContentResponsive>Vitalid</CardDimensionContentResponsive>
          <CardDimensionContent>Vitalidade</CardDimensionContent>
        </CardDimension>
      </ContainerCardDimension>

    </div>
  );
};


export default MonthReportLineChart;