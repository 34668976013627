import { endOfMonth, getDate } from "date-fns"
import { useEffect, useState } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import CustomCard from "../../components/customCard"
import Header from "../../components/header"
import { BackButtonSignUp, ContainerSignUp, ContainerSignUpButtons, NextButtonSignUp, StyledInputSignUp, StyledSignUpText, ContainerSignUpAdmin, TitleSignUpAdmin, StyledInputSignUpAdmin, StyledSignUpTextAdmin } from "../../components/renderField/styles"
import { useAuth } from "../../context/AuthContext"
import { useToast } from "../../context/Toast"
import { getUserByEmail, resendConfirmationCode, sendEmailAuthAdmin, sendSurveys, verifyCodeEmail } from "../../services/user/user"
import { StyledContainerLine, StyledContainerLineAdmin, StyledContainerParagraph, StyledParagraph, StyledParagraphAdmin, StyledSwitchButton, StyledSwitchButtonAdmin } from "./styles"

const SignUpAdmin: React.FC = () => {
    const [emailInputValue, setEmailInputValue] = useState('')
    const { showToast } = useToast()
    const location = useLocation()
    const { user, saveDataVerifyOnStorage, saveDataSignUpOnStorage, SignOut } = useAuth()
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {

        const getTokenFromUrl = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams(location.search)
            const url: any = {}
            if (queryParams.get('token')) {
                url.verifyToken = queryParams.get('token');
            }

            if (queryParams.get('email')) url.user = {
                email: queryParams.get('email'),
            }

            if (Object.keys(url).length !== 0) {
                saveDataVerifyOnStorage(url)
                if (queryParams.get('email')) {
                let result = null
                try {
                    result = await getUserByEmail(queryParams.get('email'))
                } catch (error) {
                    showToast('O link que você utilizou para acessar é inválido.\n\nCaso precise de ajuda, fale com nosso suporte usando o botão "Ajuda" na parte inferior da tela.', 'error', 30000);
                    throw error
                }

                const dataToSave = {
                    id: result?.id,
                    email: result?.email,
                    status: result?.status,
                    first_name: result?.first_name,
                    last_name: result?.last_name,
                    company_id: result?.company?.Item?.id,
                    company_name: result?.company?.Item?.name_company,
                    role: result?.role,
                }
                saveDataSignUpOnStorage(dataToSave)
                }
                checkLocalStorageUserData();
            }
            setLoading(false);
        }
        
        getTokenFromUrl()

        const checkLocalStorageUserData = async () => {
            const authUser = localStorage.getItem('zenbox.identity');
            const user = authUser ? JSON.parse(authUser) : null;
            
            if(user) {
                navigate('/admin')
            }
        }
    
    }, [location])

    const onSubmit = async () => {

        if (!emailInputValue) return (
            showToast('Você precisa informar um e-mail.', 'error')
        )
        
        if (!validEmailRegex.test(emailInputValue.trim())) return (
            showToast('Informe um e-mail válido!', 'error')
        )

    
        setLoading(true)
        try {
            await sendEmailAuthAdmin(
                emailInputValue.trim()
            )
            showToast('Link de acesso enviado :)\n\nFoi enviado no seu e-mail um link especial para que possa acessar o painel de gestão.', 'success', 15000)
        } catch (error: any) {
            if(error?.response?.status === 404 && error?.response?.data?.error === 'Não foi encontrado nenhum usuário com esse e-mail'){
                showToast('Não foi encontrada nenhuma conta com o e-mail informado.', 'error', 5000)

            } else if (error?.response?.status === 403) {
                if(error?.response?.data?.error === 'Usuário sem permissão'){

                    showToast('Para acessar a área do Gestor de Felicidade você precisa ser um administrador.', 'info', 10000)

                } else if(error?.response?.data?.error === 'Usuário inativo') {
                    showToast('Sua conta está inativada.\n\nPara ativá-la entre em contato com o Gestor de Felicidade da sua organização', 'info', 10000)
                } else if(error?.response?.data?.error === 'Usuário convidado ainda não ativou a conta') {
                    showToast('Você primeiro precisa ativar sua conta.', 'error', 5000)
                    showToast('Ative sua conta acessando: web.zenbox.life', 'info', 15000)
                } else {
                    showToast('Você não tem permissão para fazer isso.\n\nSe precisar de ajuda entre em contato com o nosso suporte clicando no botão "Ajuda" no canto inferior direito da tela.', 'error', 5000) 
                }
               
            } else {
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela.', 'error', 30000);
                setLoading(false)
                throw error
            }
            
            
        }
        
        setLoading(false)
    }

    return (
        <>
            {/* <Header /> */}
            <Container  fluid className="d-flex h-100 mt-5 mb-2">
                <Row className="justify-content-center align-self-center w-100">
                    <Col md={9} lg={7}>
                        <CustomCard>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{
                                    minHeight: '300px',

                                }}>
                                    <Spinner style={{ color: '#c9c9c9' }}></Spinner>
                                </div>
                            ) : (
                                <ContainerSignUpAdmin>
                                    <div className="d-flex justify-content-center flex-column ">
                                        <TitleSignUpAdmin>Olá! Aqui você faz o seu acesso de gestor</TitleSignUpAdmin>
                                        <StyledSignUpTextAdmin>{'Qual é o seu e-mail corporativo?'}</StyledSignUpTextAdmin>
                                        <div className="d-flex justify-content-center" style={{
                                            width: '100%',
                                            flexDirection: 'column',
                                        }}>
                                            <StyledInputSignUpAdmin
                                                id={`email-singup`}
                                                type='email'
                                                value={emailInputValue ? emailInputValue : ''}
                                                rows="1"
                                                onChange={(e) => setEmailInputValue(e.target.value?.toLowerCase())}
                                                
                                            />

                                        </div>
                                    </div>
                                    
                                    {/* <StyledContainerParagraph>
                                        <StyledContainerLineAdmin></StyledContainerLineAdmin>
                                        <StyledParagraphAdmin>ou</StyledParagraphAdmin>
                                        <StyledContainerLineAdmin></StyledContainerLineAdmin>
                                    </StyledContainerParagraph>
                                    
                                    <StyledSwitchButtonAdmin onClick={() => navigate('/admin/cellphone-auth')}>Fazer login utilizando o número de celular</StyledSwitchButtonAdmin> */}
                                    
                                    <ContainerSignUpButtons>
                                        <NextButtonSignUp onClick={onSubmit}> Enviar código de acesso</NextButtonSignUp>
                                    </ContainerSignUpButtons>
                                </ContainerSignUpAdmin>
                            )}
                        </CustomCard>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SignUpAdmin
