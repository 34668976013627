import React, { useEffect, useState } from 'react';
import { FooterContainer, TextFooter } from '../../pages/welcome/styles';
import Logo from '../../../src/assets/logo-gradient-zenbox.svg'

const Footer: React.FC = () => {
    const [isAdminPage, setIsAdminPage] = useState(false);

    useEffect(() => {
        setIsAdminPage(window.location.pathname.includes('admin'));
    }, []);

    return(
        <div style={{maxHeight: '100vh'}}>
            <FooterContainer style={{ backgroundColor: (isAdminPage ? 'transparent' : 'transparent'), color: (isAdminPage ? '#808086' : '#FFF') }}>
                <TextFooter>powered by</TextFooter>
                <img src={Logo} draggable ="false" alt="Logo" style={{ width: '15%', maxWidth: '70px', minWidth: '60px', filter: 'drop-shadow(rgba(0, 0, 0, 0.5) 0px 1px 1px)' }} />
            </FooterContainer>
        </div>
    )

}

export default Footer;