import { Card, FormLabel } from "react-bootstrap";
import styled from "styled-components";
import {Form} from "react-bootstrap"
import { StyledOption } from "../settings/modalUser/styles";
import { Input } from "reactstrap";

export const StyledCardTitle = styled(Card.Text)`
  color: #FFF;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 0 1em 0;
  @media (min-width: 768px){
    padding: 2em;
  }
`;

export const LabelInput = styled.div`
  display: flex;
  gap: .5em;
  margin-top: 1em;
  grid-template-columns: 2fr;
  flex: 1 1 0;
  justify-content: center;
  align-items: flex-start;
  min-width: 16px;
  
  
  @media (min-width: 1200px){
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
  }

`;

export const LabelCheckbox = styled(FormLabel)`
  text-align: start;
  font-size: 16px;
  margin: 0;
  @media (max-width: 768px){
    margin-top: .5em;
  }

  @media (max-width: 1100px){
    margin-top: .2em;
  }

  @media (min-width: 1200px){
    margin-top: .55em;
  }

`;

export const LabelNames = styled(FormLabel)`
  text-align: start;
  margin: .3em 0;
  width: 100%;

  @media (min-width: 1200px){
    font-size: 17px;
  }

  @media (min-width: 1440px){
    font-size: 18px;
  }

`;

export const StyledOptions = styled.option`
  font-size: 16px;
`;

export const StyledLink = styled.a`
  color: #FFF ;
  opacity: .5;
`;


export const StyledTextEmployee = styled(Input)`
  border-radius: 6px;
  background: #2A2A48;
  color: #FFF; 
  padding: 10px; 
  padding-right: 40px;
  min-height: 52px;
  border-color: #23233C;
  &:focus {
    color: #FFF  ; 
    outline: none; 
    background-color: #2A2A48;
  
  }

  &::placeholder { 
    color: #FFF;
    opacity: 1  !important;
  }


  &::-webkit-input-placeholder {
    color: #FFF;
    opacity: 1 !important;
  }


  &::-moz-placeholder {
    color: #FFF;
    opacity: 1 !important;
  }


  &:-ms-input-placeholder { 
    color: #FFF;
    opacity: 1 !important;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  @media (max-width: 694px){
    background-color: #2A2A48;
  }
    &:focus {
    outline: none; 
    background:  #2A2A48; 
  
  }
  


`;
