import styled from "styled-components";


export const StyledSwitchButton =  styled.button`
    border: none;
    background: none;
    text-decoration: underline;
    font-size: 18px;
    color: #FFF;
`;

export const StyledSwitchButtonAdmin =  styled.button`
    border: none;
    background: none;
    text-decoration: underline;
    font-size: 18px;
    color: #3C4043;
`;


export const StyledContainerParagraph = styled.div`
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;

`;

export const StyledParagraph = styled.p`
    font-weight: bold;
    color: #999898;
    opacity: .5;
    font-size: 20px;
    margin: 0;

`;

export const StyledContainerLine = styled.div`
    background-color: #999898;
    opacity: .5;
    height: 1px;
    width: 100%;
`;

export const StyledParagraphAdmin = styled.p`
    font-weight: bold;
    color: #3C4043;
    opacity: .5;
    font-size: 20px;
    margin: 0;

`;

export const StyledContainerLineAdmin = styled.div`
    background-color: #3C4043;
    opacity: .5;
    height: 1px;
    width: 100%;
`;

export const StyledLoginButton = styled.button`
    background:linear-gradient(90deg, #1BD7F2 0%, #1AD9F2 105.53%);
    border-color:transparent;
    white-space: nowrap;
    cursor: pointer;
    color: #3C4043;
    width: 100%;
    height: 52px;
    padding: 0 2em;
    max-width: 160px;
    border-radius: 5px;
    @media (max-width: 361px){
    width: 100%;
    }

`;

export const StyledBackButton = styled.button`
    background:none;
    border: none;
    cursor: pointer;
    color: #FFF;
    border: 1px solid #FFF;
    width: 100%;
    height: 52px;
    padding: 0 3em;
    max-width: 160px;
    border-radius: 5px;

    @media (max-width: 361px){
    width: 100%;
    }

`;

export const StyledBackButtonAdmin = styled.button`
    background:none;
    border: none;
    cursor: pointer;
    color: #3C4043;
    border: 1px solid #3C4043;
    width: 100%;
    height: 52px;
    padding: 0 3em;
    max-width: 160px;
    border-radius: 5px;

    @media (max-width: 361px){
    width: 100%;
    }

`;

export const StyledContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2em;

`;

export const StyledParagraphConfirmationCode = styled.p`
    color: #FFF;
    text-align: left;
    opacity: .7;

`;

export const StyledConfirmationCodeText = styled.p`
  color: #FFF;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: .5em;

  @media (min-width: 1200px){
    font-size: 24px;
    
  }


`;
