import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { NavLink, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Rectangle, SidebarContainer, StyledNavLink } from './styles';
import { Badge,  Drawer, Icon,   Slide,   Tooltip,   useMediaQuery} from '@mui/material';
import { DescriptionOutlined, InsightsOutlined, Menu, SettingsOutlined, HelpOutlineOutlined } from '@mui/icons-material';
import './index.css';
import { useDrawerContext } from '../../context/DrawerContext';
import { themeLight } from '../../theme/theme';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { keyframes } from '@emotion/react';


export const iconPaths: any = {

    sustentabilidade: require('../../assets/ic_twotone-speed.png'),
    calendario: require('../../assets/calendar (5) 1.png'),
    relatorio: require('../../assets/file-text (3) 1.png'),
    insight: require('../../assets/Insights.png'),

};

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    theme: any
}

const Sidebar = ({ children }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const theme = useTheme();

    const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

    const {isDrawerOpen, toggleDrawerOpen} = useDrawerContext() //contexto que faz o drawer fechar quando esta em telas pequenas e clicar em botão


    const menuItem = [
        {
            path: "/admin/insights",
            name: "Insights",
            icon: iconPaths['insight']
        },
        // {
        //     path: "/admin/calendario",
        //     name: "Calendário",
        //     icon: iconPaths['calendario']
        // },
        // {
        //     path: "/admin/sustentabilidade",
        //     name: "Sustentabilidade",
        //     icon: iconPaths['sustentabilidade']
        // },
        {
            path: "/admin/relatorio",
            name: "Relatório",
            icon: iconPaths['relatorio']
        },
        {
            path: "/admin/configuracoes",
            name: "Configurações",
            icon: iconPaths['configuracao']
        },
    ]

    




    const drawerWidth = 240;
    const openDrawerAnimation = keyframes`
        0% { width: 0; }
        100% { width: ${drawerWidth}px; }
    `;

    


    const openedMixin = (theme: Theme): CSSObject => ({
        width: drawerWidth,
        height: '100vh',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut, // Altere para 'easeOut' para uma transição mais suave
            duration: theme.transitions.duration.shorter,
           
        }),
        animation: `${openDrawerAnimation} ${theme.transitions.duration.enteringScreen}ms`,
        overflowX: 'hidden',
        "@keyframes openDrawer": {
            "0%": { width: '0px' },
            "100%": { width: drawerWidth },
        },
    });

    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeIn, // Altere para 'easeIn' para uma transição mais suave
            duration: theme.transitions.duration.shorter,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(9)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
      })<AppBarProps>(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
    }));


    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
          width: drawerWidth,
          flexShrink: 0,
          height: '100vh',
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          }),
        }),
    );


    
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [selectedIcon, setSelectedIcon] = useState<number | null>(() => {
        // Recuperar o estado inicial do localStorage
        const saved = localStorage.getItem('selectedIcon');
        return saved !== null ? Number(saved) : null;
    });


    useEffect(() => {
        // Salvar o estado atual no localStorage sempre que selectedIcon mudar
        localStorage.setItem('selectedIcon', String(selectedIcon));
    }, [selectedIcon]);
      
    useEffect(() => {
        
        if (window.location.pathname === '/admin/insights') {
          
          setSelectedIcon(0);
        }

        if (window.location.pathname === '/admin/relatorio') {
            
            setSelectedIcon(1);
        }

        if (window.location.pathname === '/admin/configuracoes') {
          
            setSelectedIcon(2);
        }
    }, []);  

    return (

        



        <Box sx={{ display: 'flex' }}>

            {/* <Slide direction='right' in={open} mountOnEnter unmountOnExit>


            </Slide> */}
           
            <Drawer sx={{display:'flex', flexDirection: 'column '}} variant={lgDown ?  'temporary' : 'permanent'} open={open}   
                PaperProps={{
                sx: {
                    backgroundColor: '#F0F4F9', 
                    border: 'none',
                },
            }}>
                <DrawerHeader sx={{display: 'flex', justifyContent: open ? 'initial' : 'center'}}>
                    <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {open ? <ChevronLeftIcon sx={{fontSize: 25, color:'#3C4043'}} /> : <MenuIcon sx={{fontSize: 25, color:'#3C4043'}} />}
                    </IconButton>
                </DrawerHeader>
                <Box sx={{display: 'flex', flexDirection:'column', height:'100%',  justifyContent: 'space-between'}}>
                    <List sx={{display: 'flex', flexDirection:'column', justifyContent: 'center', gap: '1em', marginTop:'3em'}}>

                       


                        <StyledNavLink to='/admin/insights' key={0}   >
                            <Tooltip title={'Insights'} placement='right' arrow enterTouchDelay={0} leaveTouchDelay={2000}>
                                <ListItemButton
                                        className={selectedIcon === 0 ? 'selected-list-item' : ''}
                                    sx={{
                                        gap:' 1em',
                                        justifyContent: open ? 'center' : 'center',
                                        px: 2.5,
                                        backgroundColor: selectedIcon === 0 ? ' rgba(113, 237, 203, 0.40);' : 'transparent',
                                        borderRadius: '0 20px 20px 0',
                                        padding:'1em 2em',
                                        display: 'flex',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: selectedIcon === 0 ? ' rgba(113, 237, 203, 0.40);' : '',
                                        },
                                        
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            left: 0,
                                            width: selectedIcon === 0 ? '5px' : '0',
                                            backgroundImage: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',

                                        }
                                    }}
                                    onClick={() => setSelectedIcon(0)}
                                    
                                >
                                    <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 0 : 'auto',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    
                                    }}
                                
                                    >
                                        <InsightsOutlined sx={{fontSize: 25, color:'#3C4043'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Insights'  sx={{ display: open ? 'block' : 'none', fontSize: '16px' }} />
                                </ListItemButton>
                            </Tooltip>
                           
                        </StyledNavLink> 

                     
                       


                        <StyledNavLink to='/admin/relatorio' key={1}   >
                            <Tooltip title={'Relatório Mensal'} placement='right' arrow enterTouchDelay={0} leaveTouchDelay={2000}>
                                <ListItemButton
                                    sx={{
                                        gap:'1em',
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        backgroundColor: selectedIcon === 1 ? ' rgba(113, 237, 203, 0.40);' : 'transparent',
                                        borderRadius: '0 20px 20px 0',
                                        position: 'relative',
                                        padding: open ? '1em 2em' :  '1em 2em',
                                        '&:hover': {
                                            backgroundColor: selectedIcon === 1 ? ' rgba(113, 237, 203, 0.40);' : '',
                                        },
                                        '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        minHeight: 57,
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        width: selectedIcon === 1 ? '5px' : '0',
                                        backgroundImage: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',

                                        }
                                        
                                    }}
                                    onClick={() => setSelectedIcon(1)}
                                    
                                >
                                    <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 0 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    >
                                        <DescriptionOutlined sx={{fontSize: 25, color:'#3C4043'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Relatório Mensal' sx={{ display: open ? 'block' : 'none' }} />
                                </ListItemButton>
                            </Tooltip>
                        </StyledNavLink>


                        <StyledNavLink to='/admin/configuracoes' key={2}   >
                            <Tooltip title={'Configurações'} placement='right' arrow enterTouchDelay={0} leaveTouchDelay={2000}>
                                <ListItemButton
                                    sx={{
                                    gap:'1em',
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: selectedIcon === 2 ? ' rgba(113, 237, 203, 0.40);' : 'transparent',
                                    borderRadius: '0 20px 20px 0',
                                    padding:'1em 2em',
                                    '&:hover': {
                                        backgroundColor: selectedIcon === 2 ? ' rgba(113, 237, 203, 0.40);' : '',
                                    },
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        width: selectedIcon === 2 ? '5px' : '0',
                                        backgroundImage: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',

                                    }
                                    }}
                                    onClick={() => setSelectedIcon(2)}
                                    
                                >
                                    <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 0 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    >
                                        <SettingsOutlined sx={{fontSize: 25, color:'#3C4043'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Configurações' 
                                    sx={{ 
                                        
                                        display: open ? 'block' : 'none',  
                                        '& .MuiListItemText-root': {
                                            fontFamily: '"Product Sans", sans-serif !important'
                                        }
                                    }} 
                                    
                                    />
                                </ListItemButton>
                            </Tooltip>
                        </StyledNavLink>


                              

                       



                    </List>

                    <List>
                        
                        <Tooltip title={'Central de ajuda'} placement='right' arrow enterTouchDelay={0} leaveTouchDelay={2000}>
                            <ListItemButton
                                sx={{
                                    gap: '1em',
                                    justifyContent: open ? 'start' : 'center',
                                    px: 2.5,
                                    padding:'1em 2em',
                                    borderRadius: '0 20px 20px 0',
                                }}
                            >
                                

                                
                                <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 0 : 'auto',
                                        justifyContent: open ? 'initial' : 'center',
                                        
                                    }} >
                                        <a href="https://zenboxsupport.zendesk.com/hc/pt-br" target="_blank" rel="noopener noreferrer">
                                            <HelpOutlineOutlined  sx={{fontSize: 25, color:'#3C4043'}}/> 
                                        </a>
                                    
                                
                                </ListItemIcon>
                                <ListItemText primary='Central de Ajuda' sx={{ display: open ? 'block' : 'none' }} />
                                
                            </ListItemButton>

                        </Tooltip>

                                
                    </List>     



                </Box>
               
               
            </Drawer>


            <Box style={{padding: '0', background:'#F5F7F8', overflow: 'auto'}} width='100%' component='main'>
                 {children}
            </Box>

        </Box>
        
    );
};

export default Sidebar;