import request from '../config/request'

import requestBack from '../config/request-back'

const signup = async (authData: any) => {
	let response = await request({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_HOST + `/signup`,
		data: authData
	})

	return response
}

const verifyCodeEmail = async (authData: any) => {
	let response = await request({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_HOST + `/verify-code-email`,
		data: authData
	})

	return response
}

const newUser = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/new-user`,
		data: authData,
		vtAuth: true
	})

	return response
}

const updateUser = async (authData: any) => {
	let response = await requestBack({
		method: 'PUT',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/update-user`,
		data: authData,
		vtAuth: true
	})

	return response
}

const getUserByEmail = async (email: any) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/user-by-email/${email}`,
		vtAuth: true
	})

	return response
}

const getUserByCellphone = async (cellphone: any) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/user-by-cellphone/${cellphone}`,
		vtAuth: true
	})

	return response
}

const sendSurveys = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/send-surveys`,
		vtAuth: true,
		data: authData,
	})

	return response
}

const getUsersByCompany = async (filter:any,page:number,) => {
	let query = "?page=" + page 
    if (filter.company_id) {
        query += "&company_id=" + filter.company_id
    }
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/users-by-company`+ query,
		vtAuth: true,
	})

	return response
}

const findUser = async (filter:any) => {
	let query = "?company_id=" + filter.company_id
    if (filter.search) {
        query += "&search=" + filter.search
    }
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/search-user-by-company`+ query,
		vtAuth: true,
	})

	return response
}

const getUsersByGroup = async (filter:any,page:number,) => {
	let query = "?page=" + page 
    if (filter.company_id) {
        query += "&company_id=" + filter.company_id
    }
	if (filter.group_id) {
        query += "&group_id=" + filter.group_id
    }
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/users-by-company-and-group`+ query,
		vtAuth: true,
	})

	return response
}

const getUserById = async (id: any) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/user-by-id/${id}`,
		vtAuth: true
	})

	return response
}

const resendConfirmationCode = async (email: string) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_HOST + `/resend-confirmation-code`,
		data: {email: email},
	})

	return response
}

const verifyUserCellphone = async (cellphone: string) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/login-with-cellphone`,
		data: {cellphone: cellphone},
	})

	return response
}

const sendEmailAuthAdmin = async (email: string) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/send-email-auth-admin`,
		data: {address: email},
	})

	return response
}

export { signup, verifyCodeEmail, newUser, getUserByEmail, sendSurveys, getUsersByCompany, findUser, updateUser, getUsersByGroup, getUserById, resendConfirmationCode, verifyUserCellphone, sendEmailAuthAdmin, getUserByCellphone }
