import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR } from '@mui/x-date-pickers/locales'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React, { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import useHotjar from 'react-use-hotjar'
import Footer from './components/footer'
import { AuthProvider } from './context/AuthContext'
import { DrawerProvider } from './context/DrawerContext'
import { QuestionProvider } from './context/QuestionContext'
import { ToastifyProvider } from './context/Toast'
import UserRoutes from './routes/UserRoutes'
import { themeLight } from './theme/theme'

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.locale('Pt-br')

dayjs.extend((o, c) => {
	const proto = c.prototype;
	const oldFormat = proto.format;
	proto.format = function (formatStr) {
	  const str = oldFormat.call(this, formatStr);
	  return str.charAt(0).toUpperCase() + str.slice(1);
	};
});

const App: FC = function App() {
	const {initHotjar} = useHotjar();
	React.useEffect(() => {
		initHotjar(2783598, 6, false);
	}, [initHotjar]);
	
	return (
		<ToastifyProvider>
			<LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
				<AuthProvider>
					<DrawerProvider>
						<ThemeProvider theme={themeLight}>
							<QuestionProvider>
								<Router basename={'/'}>
									<UserRoutes />
								</Router>
							</QuestionProvider>
						</ThemeProvider>
					</DrawerProvider>
				</AuthProvider>
				<Footer/>
			</LocalizationProvider>
		</ToastifyProvider>
	)
}

export default App;