export enum DimensionID {
    POSITIVE_EMOTIONS = 1,
    ENGAGEMENT = 2,
    RELATIONSHIP = 3,
    MEANING = 4,
    ACCOMPLISHMENT = 5,
    VITALITY = 6
}

export const DimensionFormattedName: { [dimensionId: number]: string } = {
    [DimensionID.POSITIVE_EMOTIONS]: "Emoções Positivas",
    [DimensionID.ENGAGEMENT]: "Engajamento",
    [DimensionID.RELATIONSHIP]: "Relacionamento",
    [DimensionID.MEANING]: "Significado",
    [DimensionID.ACCOMPLISHMENT]: "Realização",
    [DimensionID.VITALITY]: "Vitalidade",
}