import { Input } from "reactstrap";
import styled from "styled-components";


export const ContainerDashboard = styled.div`
    padding: 0 2em;
    @media(max-width: 768px){
        padding: 0;
    }

`;

export const ContainerSelectFilters = styled.div`
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media(max-width: 694px){
        padding: 1em 0;
        flex-direction: column;
    }

    @media(min-width: 728px) and (max-width: 768px){
        padding: 1em 0;
        gap: 0;
    }

`;

export const ContainerFilters = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    width: 49.3%;
    @media(max-width: 694px){
        flex-direction: column;
        width: 100%;
        gap: 1em;
        margin-top: 1em;
    }

`

export const StyledSelectMonth = styled(Input)`
    background:  #FFF;
    color: #3C4043;
    border: none;
    width: 34%;
    padding: .88em;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    font-weight: 700;
    text-transform: lowercase;
    cursor: pointer;

    &::first-letter{
        text-transform: uppercase;
    }

    @media(max-width: 694px){
        background: #FFF;
        width: 90%;
        text-align: center;
    }

    @media (max-width: 1400px){
      font-size: .9em;
      min-height: 52.60px;    
    }

   
`;

export const ContainerCardMatrix = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1em;
    gap: 1em;
    @media (max-width: 768px){
        grid-template-columns: 1fr;
    }
`;

export const ContainerOverflowCharts = styled.div`
    overflow-x: auto;
    min-width: 1000px;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        cursor: pointer;
        height: 8px ;
        border-radius: 1em;
        background-color:#F1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #c9c9c9 !important;
        padding: .2em !important;
        border-radius: 1em !important;
        cursor: pointer;
    }
`;