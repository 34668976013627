import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CompanyNameItem from '../companyNameItem/companyNameItem';
import { getAllCompanies } from "../../../services/company/company"; // Verifique se o caminho do seu serviço está correto
import { useAuth } from "../../../context/AuthContext";

interface ChangeCompanyDialogProps {
  show: boolean;
  onClose: () => void;
}

interface CompanyItem {
  updated_at: string,
  name_company: string,
  created_at: string,
  surveys_all_month: boolean,
  number_license_available: number,
  photo_logo: string | null,
  cnpj: string | null,
  utc_offset: string | null,
  admin_id: string[] | null,
  id: string,
  number_license_acquired: number
}

const ChangeCompanyDialog: React.FC<ChangeCompanyDialogProps> = ({ show, onClose }) => {
  const [companyList, setCompanyList] = useState<CompanyItem[]>([]);
  const { user, saveDataSignUpOnStorage } = useAuth()

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await getAllCompanies();
        setCompanyList(companies);
      } catch (error) {
        throw error;
      }
    };

    fetchCompanies();

  }, []);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      sx={{
        [`.MuiDialogContent-root`]: {
          padding: '1em 0',
        },
      }}
    >
      
      <DialogTitle sx={{ marginRight: '4em', padding: '.5em' }} id="customized-dialog-title">
        Escolha a Organização
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 2,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
     

      <DialogContent dividers>
        {companyList.map(company => (
          <CompanyNameItem key={company.id} companyName={company.name_company} onClick={() => {
            let updatedData = user.user;
            updatedData.company_id = company.id;
            updatedData.company_name = company.name_company;
            saveDataSignUpOnStorage(updatedData)

            window.location.reload();
          }} />
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default ChangeCompanyDialog;