import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext, useRef } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { StyledAsyncSelect, StyledText } from '../styles'
import { Input } from 'reactstrap'
import { LabelInput, LabelNames } from '../../userRegister/style'
import { useToast } from '../../../context/Toast'
import { StyledModal } from '../modalUser/styles'
import debounce from 'debounce-promise'
import { getUsersByCompany } from '../../../services/user/user'
import { useAuth } from '../../../context/AuthContext'


interface IFormState {
    user_id: any,

  }

function ModalAddMember({
  show,
  onClose,
  addItem,
  group_id
}: any) {

  const colourStyles: any = {
    control: (styles: any) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: undefined,
      boxShadow: 'none',
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected || isFocused
            ? '#CCCCCF'
            : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected || isFocused
            ? '#3C4043'
            : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected || isFocused)
            ? '#CCCCCF'
            : undefined,
        },
      };
    },
    singleValue: (styles: any) => ({
      ...styles,
      color: '#3C4043'
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      color: '#3C4043',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      display: 'none',

    }),
    input: (base: any) => ({
      ...base,
      color: '#3C4043',
      width: '100%',

    }),
  };

  const { showToast } = useToast()
  const [showLoading, setShowLoading] = useState(false)
  const {user} = useAuth()
  const [form, setForm] = useState<IFormState>({
    user_id: '',
  });

  const onSubmit = async () => {

    const { user_id } = form;
    if (!user_id) {

      return showToast('Preencha o campo', 'error')

    }
    if (!group_id){
        return showToast('Grupo não encontrado', 'error')
    }
    const dataToSend= {...form, id:group_id}
    dataToSend.user_id =  form.user_id.value;
    
    try {
      setShowLoading(true)
      await addItem(dataToSend)
      setForm({
        user_id: '',
      })
      showToast('Membro cadastrado', 'success')
      onClose()
    } catch (error) {
      showToast('Falha ao cadastrar membro', 'error')
    }

    setShowLoading(false)
  }

  const loadUserInputOptions = async (term: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let options: any = []
        const company = user.user?.company_id
        if(!company) throw new Error("company_not_found")
        let response: any = await getUsersByCompany({ company_id: company }, 1)

        if (!response || response?.data?.length <= 0) return resolve(options)

        response?.data?.forEach((element: any) => {
          options.push({
            label: element?.first_name + ' ' + element?.last_name,
            value: element.id
          })
        })

        return resolve(options)
      } catch (e) {
        console.error(e)
        return reject([])
      }
    })
  }

  const debounceOptionsUsers: any = debounce(loadUserInputOptions, 600)



  if (!show) return null
  return (
    <>
      <StyledModal show={show} size='lg' >
        <Modal.Header style={{background:'#FFF'}} >
          <Modal.Title style={{ color: '#3C4043' }}>Adicionar membro</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{background:'#FFF'}}>

          <Row>
            <Col xs={12} sm={12}>
              <Form.Group className="mb-3" controlId="users">
                <LabelNames style={{ color: '#3C4043' }}>Usuário</LabelNames>
                <StyledAsyncSelect
                  value={form?.user_id}
                  placeholder=''
                  name="user_id"
                  defaultOptions
                  loadOptions={debounceOptionsUsers}
                  styles={colourStyles}
                  noOptionsMessage={() => 'Sem opção'}
                  onChange={(event: any) => {
                    setForm({
                      ...form,
                      ['user_id']: event
                    })
                  }}
                />
              </Form.Group>
            </Col>

          </Row>


        </Modal.Body>
        <Modal.Footer  style={{background:'#FFF'}}>
          <Button 
            style={{background: 'transparent', borderColor:'#3C4043', color:'#3C4043'}}
            variant='secondary'
            onClick={()=>{
                setForm({
                    user_id: '',
                  })
                onClose()
            }}
            disabled={showLoading}
          >
            Fechar
          </Button>
          <Button
            style={{background:'linear-gradient(315deg, #2B81FF 12.16%, #2491FF 24.23%, #04D7FF 78.39%)', border:'none', minHeight: '38px'}}
            variant='primary'
            onClick={() => {
              onSubmit()
            }}
            disabled={showLoading}
          >
            {showLoading ? 'Confirmando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </StyledModal>
    </>
  )
}

export default ModalAddMember
