import React, { FC, ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { CardInicio } from '../../pages/welcome/styles';

interface CustomCardProps {
  children: ReactNode;
}

const CustomCard: FC<CustomCardProps> = ({ children }) => {
  return (
    <CardInicio>
      <Card.Body>
        {children}
      </Card.Body>
    </CardInicio>
  );
};

export default CustomCard;
