import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  

  .modal-header,
  .modal-footer {
    border-color: #404056; 
  }

  .modal-title, .modal-body, .modal-footer {
    color: white; 
  }
`;

export const StyledSelect = styled(Form.Select)`
  padding: 10px;
  border-radius: 6px;
  background:  #F1F1F1;
  color: #3C4043; 
  font-size: 16px;
  min-height: 52px;
  
  option{
    font-size: 18px;
    padding: 1em 0;
  }
`;


export const StyledSelectEmployee = styled(Form.Select)`
  padding: 10px;
  border-radius: 6px;
  background:  #2A2A48;
  border-color: #23233C;
  color: #FFF; 
  font-size: 16px;
  min-height: 52px;
  
  option{
    font-size: 18px;
    padding: 1em 0;
  }
`;


export const StyledSelectModalUser = styled(Form.Select)`
  padding: 10px;
  border-radius: 6px;
  color:#3C4043; 
  font-size: 16px;
  min-height: 52px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  
  &::placeholder { 
    color: #878B8E;
    opacity: 1  !important;
  }
  
  option{
    font-size: 18px;
    padding: 1em 0;
  }

  option:hover{
    background-color: #CCCCCF;
  }

  option:checked {
  background-color: #CCCCCF;
  color: #fff;
  }
`;

export const StyledOption = styled.option`
  padding: 1em 0;
  font-size: 16px;
  
`;