import requestBack from '../config/request-back'

const initAnswers = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/init-answers`,
		data: authData,
		vtAuth: true
	})

	return response
}


const updateAnswers = async (authData: any) => {
	let response = await requestBack({
		method: 'PUT',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/update-answers`,
		data: authData,
		vtAuth: true,
	})

	return response
}

export { initAnswers, updateAnswers }
