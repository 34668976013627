import requestBack from '../config/request-back'

const createGroup = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/new-group`,
		data: authData,
		vtAuth: true
	})

	return response
}


const addMember = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/add-member`,
		data: authData,
		vtAuth: true,
	})

	return response
}

const removeMember = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/remove-member`,
		data: authData,
		vtAuth: true,
	})

	return response
}


const getGroupId = async (groupId: any) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/group-by-id/${groupId}`,
		vtAuth: true,
	})

	return response
}

const getGroupByCompany = async (filter:any,page:number,) => {
	let query = "?page=" + page 
    if (filter.company_id) {
        query += "&company_id=" + filter.company_id
    }
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/groups-by-company/`+ query,
		vtAuth: true,
	})

	return response
}

const findGroup = async (filter:any,page:number,) => {
	let query = "?page=" + page 
    if (filter.company_id) {
        query += "&company_id=" + filter.company_id
    }
	if (filter.search) {
        query += "&search=" + filter.search
    }
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/search-groups-by-company/`+ query,
		vtAuth: true,
	})

	return response
}



export { getGroupId, removeMember, addMember, createGroup, getGroupByCompany, findGroup }
