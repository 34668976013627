import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const StyledToastify  = styled(ToastContainer)`

  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
    white-space: pre-line;
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast-icon {
  }
`;