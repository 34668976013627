import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface Responses {
  [key: number]: any;
}

interface QuestionContextType {
  responses: Responses;
  answerId:number|null,
  saveDataAnswerOnStorage:any,
  saveResponse: (questionId: number, answer: any) => void;
  currentQuestionIndex: number|null;
  setCurrentQuestionIndex: any;
  removeAnswer:any;
  setResponses:any;
}

const QuestionContext = createContext<QuestionContextType>({
  responses: {},
  saveResponse: () => {},
  currentQuestionIndex: null,
  setCurrentQuestionIndex: () => {},
  answerId:null,
  removeAnswer:()=>{},
  saveDataAnswerOnStorage: () => {},
  setResponses:() => {}
});

export const useQuestions = () => useContext(QuestionContext);

interface QuestionProviderProps {
  children: ReactNode;
}

export const QuestionProvider: React.FC<QuestionProviderProps> = ({ children }) => {
  const [answerId, setAnswerId] = useState(null)
  const [responses, setResponses] = useState<Responses>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number|null>(null);

  const saveResponse = (questionId: number, answer: any) => {
    setResponses({ ...responses, [questionId]: answer });
  };

  useEffect(() => {
		const questionId = localStorage.getItem('zenbox.question')
		if (questionId && JSON.parse(questionId)) {
			let dataQuest = JSON.parse(questionId)
			
			if (dataQuest?.answer_id) {
				setAnswerId(dataQuest?.answer_id)
                
			}
    }
	}, [])

  const saveDataAnswerOnStorage = (id: any) => {
		setAnswerId(id)
		
		
		localStorage.setItem(
			'zenbox.question',
			JSON.stringify({
				answer_id: id,
			})
		)
	
	}

  const removeAnswer = () =>{
    localStorage.removeItem('zenbox.question')
    setAnswerId(null)
    setResponses({})
    setCurrentQuestionIndex(0)
  }


  return (
    <QuestionContext.Provider value={{
      responses,
      saveResponse,
      currentQuestionIndex,
      answerId,
      removeAnswer,
      setResponses,
      saveDataAnswerOnStorage,
      setCurrentQuestionIndex
    }}>
      {children}
    </QuestionContext.Provider>
  );
};
