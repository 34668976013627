import styled from "styled-components";
import PhoneInput from 'react-phone-number-input'



export const StyledInputEmployee = styled(PhoneInput)`
    .PhoneInputInput{
        font-size: 18px;
        border-radius: 10px;
        width: 100%;
        outline: none;
        padding: 10px;
        transition: box-shadow ease .25s, border-color ease .25s;
        min-height: 46px;
        max-height: 46px;
        background: #2A2A48;
        color:  #FFF;
        border: 1px solid #23233C;
        width: 100%;
    }

    .PhoneInputInput:focus{
        border: 1px solid #86b7fe;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

    .PhoneInputCountry{
        margin-right: .5em;
        padding: 0 .2em 0 .5em !important;
        font-size: 18px;
        border-radius: 10px;
        outline: none;
        padding: 10px;
        transition: box-shadow ease .25s, border-color ease .25s;
        min-height: 46px;
        max-height: 46px;
        background: #2A2A48;
        color:  #FFF;
        border: 1px solid #23233C;
    }

    .PhoneInputCountry:hover{
        border: 1px solid #86b7fe;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

`

export const StyledInputAdmin = styled(PhoneInput)`
    .PhoneInputInput{
        font-size: 18px;
        border-radius: 10px;
        width: 100%;
        outline: none;
        transition: box-shadow ease .25s, border-color ease .25s;
        min-height: 46px;
        max-height: 46px;
        background: #F1F1F1;
        color: #3C4043;
        width: 100%;
        border: 1px solid #dee2e6;
        padding: 10px;
    }

    .PhoneInputInput:focus{
        border: 1px solid #86b7fe;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

    .PhoneInputCountry{
        margin-right: .5em;
        padding: 0 .2em 0 .5em !important;
        font-size: 18px;
        border-radius: 10px;
        outline: none;
        padding: 10px;
        transition: box-shadow ease .25s, border-color ease .25s;
        min-height: 46px;
        max-height: 46px;
        background:  #F1F1F1;
        color:  #FFF;
        border: 1px solid #dee2e6;
    }

    .PhoneInputCountry:hover{
        border: 1px solid #86b7fe;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

    .PhoneInputCountrySelectArrow{
        color: #3C4043;
    }

`