import { get } from 'http';
import { Card } from 'react-bootstrap';
import { Input } from 'reactstrap';
import styled from 'styled-components';

export const StyledCardTitle = styled(Card.Title)`
  color: #FFF;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const StyledCardSubtitle = styled(Card.Subtitle)`
  color: #FFF;
  opacity: 0.7;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: 1200px){
    font-size: 14px;
  }
`;

export const StyledCardSubtitleFinalMsg = styled(Card.Subtitle)`
  font-size: 24px;
  color: #FFF;
  @media(max-width: 694px){
    font-size: 16px;
  }
`

export const StyledCardText = styled(Card.Text)`
  margin-top:6px;
  color: #FFF;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 55px;
  max-height: 55px;

  @media (min-width: 1200px){
    font-size: 24px;
    padding: 0 2em 1em 0;
  }

`;


export const StyledSignUpText = styled.p`
  color: #FFF;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  @media (min-width: 1200px){
    font-size: 22px;
    
  }


`;

export const StyledSignUpTextAdmin = styled.p`
  color: #3C4043;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  @media (min-width: 1200px){
    font-size: 20px;
    
  }


`;


export const StyledSkip = styled(Card.Text)`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  transition: color 0.3s, color 0.3s; 
  @media (min-width: 1200px){
    font-size: 16px;
  }

  @media (max-width: 375px){
    right: 10px;
    padding: .1em;
  }


  

  &:hover {
    color: #FFF; 
  }

`;

export const ButtonSkip = styled.button`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.9;
  transition: color 0.3s, color 0.3s; 


  position: absolute;
  top: 10px;
  right: 20px;
  padding: 5px 10px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;

  @media (min-width: 1200px){
    font-size: 16px;
  }

  @media (max-width: 375px){
    right: 10px;
    padding: .1em;
  }


`;



export const NextButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px 50px; 
  min-height: 56px;
  gap: 10px; 
  border: ${(props) => (props.disabled ? '1px solid #FFF' : '1px solid #1BD7F2')};
  border-radius: 5px; 
  background: ${(props) => (props.disabled ? 'none' : 'linear-gradient(90deg, #1BD7F2 0%, #1AD9F2 105.53%)')};
  opacity: ${(props) => (props.disabled ? '.3' : '1')}; ;
  color: ${(props) => (props.disabled ? '#A5A5AA' : '#000000')}; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 
  margin-top: 1em;

  &:hover {
    cursor:  ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};; 
    
  }

  &:focus {
    outline: none; 
  }

  @media (min-width: 694px){
    width: 100%;
    max-width: 193px;
    
  }

  @media (max-width: 411px){
    width: 100%;
    
  }

`;

export const SpanNextButton = styled.span`
  @media (max-width: 411px){
    width: 100%;
    
  }
  @media (min-width: 694px){
    width: 100%;
    max-width: 193px;
    
  }

  
`

export const BackButton = styled.button`
  display: inline-flex;
  padding: 15px 49px;
  justify-content: center;
  align-items: center;
  gap: 10px; 
 border-radius: 0.30369rem;
  border: 0.972px solid #FFF;
  background-color: transparent; 
  color: #FFF; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 
  margin-top: 1em;
  &:hover {
    background-color: #FFF; 
    color: #000; 
  }

  &:focus {
    outline: none; 
  }

  @media (min-width: 694px){
    width: 100%;
   
    max-width: 193px;
   }

   @media (max-width: 411px){
    width: 100%;
    
  }
`;



export const BackButtonSignUp = styled.button`
  cursor: pointer;
  padding: 1em 3.18em;
  border-radius: 0.30369rem;
  border: 0.972px solid #FFF;
  background-color: transparent; 
  color: #FFF; 
  max-height: 56px;
  @media (max-width: 361px){
    width: 100%;
  }
`

export const NextButtonSignUp = styled.button`
  background:linear-gradient(90deg, #1BD7F2 0%, #1AD9F2 105.53%);
  border: none; 
  border-radius: 5px; 
  cursor: pointer;
  padding: 1em 2.5em;
  color: #3C4043;

  @media (max-width: 361px){
    width: 100%;
  }
`




//  GESTÃO DAS CORES DO RASTRO
export const getEndColor = (value:number) => {
  switch (value) {
    case 1:
      return '#FD4659';
    case 2:
      return '#FA7F63';
    case 3:
      return '#FFDB23';
    case 4:
      return '#00B2B5';
    case 5:
      return ' #AE2EFE';
    default:
      return '#fff';
  }
}

export const getInitialColor = (value:number) => {
  switch (value) {
    case 1:
      return '#FEA993';
    case 2:
      return '#FEE073';
    case 3:
      return '#FFF676';
    case 4:
      return '#96FFD2';
    case 5:
      return '#DDBEFF';
    default:
      return '';
  };
}
//  GESTÃO DAS CORES


//  GESTÃO DAS CORES DO PONTEIRO DO SLIDER
export const finalColorSlider = (value:number) =>{
  switch (value) {
    case 1:
      return 'radial-gradient(circle, #FD4659 55%, #8E4448 45%)';
    case 2:
      return 'radial-gradient(circle, #FA7F63 55%, #B7764F 45%)';
    case 3:
      return 'radial-gradient(circle,#FFDB23 55%, #B4A947 45%)';
    case 4:
      return 'radial-gradient(circle, #00B2B5 55%, #259B90 45%)';
    case 5:
      return 'radial-gradient(circle, #AE2EFE 55%, #8744B5 45%)';
    default:
      return 'radial-gradient(circle, #545454 55%, #464646 45%);';
  };
    
}
//  GESTÃO DAS CORES DO PONTEIRO DO SLIDER

export const StyledInput = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 13px;
  height: 31px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  background:  linear-gradient(90deg, #999 0%, rgba(196, 196, 196, 0.50) 100%);


  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
    border: none;
    transition: .2s ease-in-out;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
    border: none;
    transition: .2s ease-in-out;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  


  

  

`;


export const SliderTicks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
  color: #FFF;
  position: relative; 
  font-size: 24px;

  @media(min-width: 1200px){
    font-size: 34px;
    padding: 0 2em;
    
  }

  

  span {
    position: relative;
    display: block; 
    color: #FFF;
    padding: 0 .1em;
    @media(min-width: 1200px){
      font-size: 34px;
      
    
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%; 
      transform: translateX(-50%); 
      top: -8px; 
      width: 2px; 
      height: 8px; 
      opacity: 0.5;


      background-color: #D9D9D9; 
    }
  }
`;

export const SliderLabelContainerResponsive = styled.div`
  display: flex;
  gap: 1em;

  /* @media(max-width: 1200px){
    
    margin: 0 2em;
  }  */

 
  
`;

export const ContainerSlider = styled.div`
  @media(min-width: 1200px){
    padding: 3.3em 0; 
  }

  @media(min-width: 1440px){
    padding: 5em 0; 
  }

`;

export const SliderLabelResponsive = styled.span`
  font-weight: 400;
  color:  #FFF;

  @media(max-width: 694px){
    display: none;
    box-shadow: none;
  }
  
  @media(max-width: 1199px){
    display: none;
  }

  
`;
export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 3.5em 2em; */

`;

export const SliderLabelContainer = styled.div`
  display: flex;
  justify-content: space-between; 
  margin-bottom:1em;
  
`;

export const SliderLabel = styled.span`
  color:  #FFF; // Cor do texto, ajuste conforme necessário
  @media(min-width: 1200px){
    display: none;
  }
`;

export const StyledFinalQuestion = styled(Input)`
  border-radius: 10px;
  border: 3px solid  #23233C;
  background: #2A2A48;
  color: #FFF; 
  padding: 10px; 
  width: 100%;
  height: 150px;


  &:focus {
    background: #2A2A48; 
    border-color: #6465A5;
    outline: none; 
    color: #FFF;
  }

  &::placeholder { 
    color: #FFF; 
    opacity: 0.4  !important;
  }


  &::-webkit-input-placeholder {
    color: #FFF; 
    opacity: 0.4 !important;
  }


  &::-moz-placeholder {
    color: #FFF; 
    opacity: 0.4 !important;
  }


  &:-ms-input-placeholder { 
    color: #FFF; 
    opacity: 0.4 !important;
  }
`;

export const StyledTextArea = styled(Input)`
border-radius: 10px;
border: 3px solid #23233C;
background: #2A2A48;
color: white; 
padding: 10px; 
width: 80%;


@media (min-width: 1440px){
  width: 70%;
  
}

@media (min-width: 1200px){
  
  
}

@media(max-width: 769px){
  width: 100%;
}


&:focus {
  color: white; 
  outline: none; 
  background: #2A2A48; 
  border-color: #6465A5; 
}

&::placeholder { 
  color: white !important;
  opacity: 0.4  !important;
}


&::-webkit-input-placeholder {
  color: white !important;
  opacity: 0.4 !important;
}


&::-moz-placeholder {
  color: white !important;
  opacity: 0.4 !important;
}


&:-ms-input-placeholder { 
  color: white !important;
  opacity: 0.4 !important;
}


`;

export const QuestionCard = styled.div`
  padding: 3em;
  display: flex;
  align-self: center;
  flex-direction: column;
  
  @media (max-width: 694px){
      width: 100%;
      padding: 3em 0;
  }
`

export const ContainerBtnNextBtnPrev = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; 
  align-items: center;
  margin-top: 1em;
  gap: 2em;
  
  @media (max-width: 411px){
    margin-top: .5em;
    flex-direction: column-reverse;
    gap: 1em;
  }

  @media (min-width: 1200px) {
    padding-bottom: 1em;
  }

`
export const ContainerSignUp = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  @media (min-width: 700px){
    padding: 2em  2em 0em 2em;
    margin: 0 2em;
  }
  @media (max-width: 694px){
    padding: 2em 0;
  }
`


export const ContainerSignUpAdmin = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  @media (min-width: 700px){
    padding: 2em  2em 0em 2em;
    margin: 0 2em;
  }
  @media (max-width: 694px){
    padding: 2em 0;
  }  



`;


export const TitleSignUpAdmin = styled.h2`
  text-align: left;
  margin-bottom: 1em  ;
  font-size: 31px;
  font-weight: 700;

`;

export const ContainerSignUpButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end; 
  align-items: center;
  margin-top: 3.5em;

  @media (max-width: 361px){
    margin-top: 2em;
    flex-direction: column;
    gap: 1em;
    width: 100%;
  }

`

export const StyledInputSignUp = styled(Input)`
  width: auto;
  border-radius: 10px;
  background: #2A2A48;
  padding: 10px;
  color:  #FFF;
  border-color: #23233C;

  &:focus {
    color: #FFF; 
    outline: none; 
    background-color: #2A2A48;
  }

  &::placeholder { 
    color: #FFF !important;
    opacity: 0.4  !important;
  }


  &::-webkit-input-placeholder {
    color: #FFF !important;
    opacity: 0.4 !important;
  }


  &::-moz-placeholder {
    color: #FFF !important;
    opacity: 0.4 !important;
  }


  &:-ms-input-placeholder { 
    color: #FFF !important;
    opacity: 0.4 !important;
  }

`;


export const StyledInputSignUpAdmin = styled(Input)`
  width: auto;
  border-radius: 10px;
  background: #F1F1F1;
  padding: 10px;
  color:  #3C4043;
  
  body{
    background: red
  }

  &:focus {
    color: #3C4043; 
     
    background: #F1F1F1;
  }

  &::placeholder { 
    color: #3C4043 !important;
    opacity: 0.4  !important;
  }


  &::-webkit-input-placeholder {
    color: #3C4043 !important;
    opacity: 0.4 !important;
  }


  &::-moz-placeholder {
    color: #3C4043 !important;
    opacity: 0.4 !important;
  }


  &:-ms-input-placeholder { 
    color: #3C4043 !important;
    opacity: 0.4 !important;
  }
`;