import axios from 'axios'
import userToken from './local-user-token'



const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json'
}

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVICES_BACK_HOST
})

// const { showToast } = useToast();
// const { SignOut } = useAuth();

async function requestBack(axiosConfig: any) {
	if (!axiosConfig)
		return null;

	if (!axiosConfig.headers) {
		axiosConfig.headers = defaultHeaders

	} else {
		if (!axiosConfig.headers['Content-Type'])
			axiosConfig.headers['Content-Type'] = defaultHeaders['Content-Type']

		if (!axiosConfig.headers['Accept'])
			axiosConfig.headers['Accept'] = defaultHeaders['Accept']
	}

	if (axiosConfig.vtAuth) {
		axiosConfig.headers['Authorization'] = await userToken()
	}

	try {
		//  console.log("axios config", axiosConfig);
		let data = await instance.request(axiosConfig)

		// if(data.status === 200) {
			if (axiosConfig.returnFullResponse)
			return data

			// if((axiosConfig.url as string).includes("statistic-ifoz-by-company"))
			// 	console.log(data)

			return data.data;
		// } else {
			// throw {status: data.status, statusText: data.statusText, content: data.data}
		// }

	} catch (e: any) {
		
		// if (userToken() && e?.response?.status == 401) {
		// 	localStorage.removeItem('zenbox.identity')
		// } teste de login implementar dps
		
		// if (e?.response?.data?.error) {
		// 	switch(e.response.data.error) {
		// 	  case "Erro ao validar o token - zenbox_backend":
		// 		showToast('Sua sessão expirou :(\n\nPor uma questão de segurança o seu login expira depois de certo tempo. Isso acontece quando você utiliza um link antigo de acesso ou fica muito tempo inativo. \n\nFaça login novamente acessando em uma nova janela o endereço "web.zenbox.life" para continuar.\n\nSe precisar de ajuda, entre em contato com nosso suporte clicando no botão de "Ajuda" na parte inferior da sua tela.', 'info', 40000)
		// 		setTimeout( () => {
		// 		  showToast('Você será redirecionado para a tela de login em 30 segundos', 'info', 30000)
		// 		  setTimeout( () => {                                
		// 			SignOut();
		// 		  }, 30000);
		// 		}, 20000);
		// 		break;
				
		// 	  default:
		// 		showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
		// 		throw e;
		// 	}
		
		// } else {
		// 	showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
		// 	throw e;;
		// }    

		throw e;
	}
}

export default requestBack
