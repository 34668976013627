// Opções do filtro de representatividade que estão no contexto de Gênero/Sexo
export const representationGenderOptions = [
    'Masculino',
    'Feminino',
    'Outros'
];

// Opções do filtro de representatividade que estão no contexto de Faixa Etária
export const representationAgeRangeOptions = [
    'Até 24 anos',
    'Entre 25 e 30 anos',
    'Entre 31 e 35 anos',
    'Entre 36 e 40 anos',
    'Entre 41 e 45 anos',
    'Entre 46 e 50 anos',
    'Entre 51 e 55 anos',
    'Entre 56 e 60 anos',
    'Acima de 61 anos'
];

// Mapeamento das labels para os valores interpretados pelo back-end para cada filtro de representatividade
export const representationOptionsValues: { [key: string]: number | number[] } = {
    ['Masculino']: 1,
    ['Feminino']: 2,
    ['Outros']: 3,
    ['Até 24 anos']: [0, 24],
    ['Entre 25 e 30 anos']: [25, 30],
    ['Entre 31 e 35 anos']: [31, 35],
    ['Entre 36 e 40 anos']: [36, 40],
    ['Entre 41 e 45 anos']: [41, 45],
    ['Entre 46 e 50 anos']: [46, 50],
    ['Entre 51 e 55 anos']: [51, 55],
    ['Entre 56 e 60 anos']: [56, 60],
    ['Acima de 61 anos']: [60, 999]
}