import { Row, TabContainer, Col, Modal } from "react-bootstrap";
import { Input, Nav,NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import Select from 'react-select';
import { FiChevronRight, FiSearch } from "react-icons/fi";
import AsyncSelect from 'react-select/async'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'


export const CustomNav = styled(Nav)`
  border-bottom: 1px solid #373956; 
  color: #878B8E; 
  display: flex;
  justify-content: flex-start;
  padding: 0 1em;

  @media (max-width: 768px){
    justify-content: space-around;
  }
  
  .nav-item {
    position: relative;
    color: #878B8E; 
  
    .nav-link {
      border: none;
      color: #878B8E; 
      font-weight: 600;
      &::after {
        content: "";
        background: transparent; 
        height: 4px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.activeNav {
        color: #3C4043; 
        
        &:after {
          background: linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%);
          transform: scale(1);
        }
      }
    }
  }
`
export const StyledTitleSettings = styled.h2`
  padding: 1em;
  font-size: 24px;
  color: #FFF;
  margin: 0;
`;

export const StyledText = styled(Input)`
  border-radius: 6px;
  background: #F1F1F1;
  color: #3C4043; 
  padding: 10px; 
  padding-right: 40px;
  min-height: 52px;
  &:focus {
    color: #3C4043  ; 
    outline: none; 
    
  
  }

  &::placeholder { 
    color: #3C4043;
    opacity: 1  !important;
  }


  &::-webkit-input-placeholder {
    color: #3C4043;
    opacity: 1 !important;
  }


  &::-moz-placeholder {
    color: #3C4043;
    opacity: 1 !important;
  }


  &:-ms-input-placeholder { 
    color: #3C4043;
    opacity: 1 !important;
  }

  @media (max-width: 694px){
    background-color: #F1F1F1;
  }
    &:focus {
    outline: none; 
    background:  #F1F1F1; 
  
  }
  


`;



export const StyledInputText = styled(Input)`
  border-radius: 6px;
  color: #3C4043; 
  padding: 10px; 
  padding-right: 40px;
  min-height: 52px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

  &:focus {
    color: white; 
    outline: none; 
  
  }

  &::placeholder { 
    
    opacity: 1  !important;
  }


  &::-webkit-input-placeholder {
   
    opacity: 1 !important;
  }


  &::-moz-placeholder {
    
    opacity: 1 !important;
  }


  &:-ms-input-placeholder { 
    
    opacity: 1 !important;
  }

  @media (max-width: 694px){
  
  }
    &:focus {
    color: #3C4043; 
    outline: none; 
  
  }
  


`;


export const StyledInputSearchUser = styled(Input)`
  border-radius: 6px;
  background-color: #FFF;
  color: #3C4043; 
  min-height: 52px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  &:focus {
    color: white; 
    outline: none; 
  
  }

  &::placeholder { 
    
    opacity: 1  !important;
  }


  &::-webkit-input-placeholder {
    
    opacity: 1 !important;
  }


  &::-moz-placeholder {
    
    opacity: 1 !important;
  }


  &:-ms-input-placeholder { 
    
    opacity: 1 !important;
  }

  @media (max-width: 694px){
  
  }
    &:focus {
    color: #3C4043; 
    outline: none;  
  
  }
  
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 60%;
  @media (max-width: 694px){
    
    width: 100%;
    
  }
  @media (min-width: 695px) and (max-width: 1150px){
    
    width: 100%;
    
  }

  
`;



export const SearchButton = styled.button`
  position: absolute;
  right: 2.5em;
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
`;

export const StyledSaveButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #1BD7F2 0%, #1AD9F2 105.53%);
  border: none;
  border-radius: .5em;
  padding: 1em 1.5em;
  transition: background-color 0.3s, color 0.3s; 

  @media (max-width: 694px){
    width: 90%;
    max-width: 193px;
    margin-top: 1em;
  }

  &:hover {
    background-color: #FFFFFF; 
    color: #FFF; 
  }

  &:focus {
    outline: none; 
  }

`

export const AddButton = styled.button`
  display: inline-flex;
  padding: .5em 1.5em;
  justify-content: center;
  align-items: center;
  gap: 10px; 
  border-radius: 6px;
  border: 1px solid var(--Gradients-Blue-Gradient, linear-gradient(315deg, #2B81FF 12.16%, #2491FF 24.23%, #04D7FF 78.39%));
  background: var(--Gradients-Blue-Gradient, linear-gradient(315deg, #2B81FF 12.16%, #2491FF 24.23%, #04D7FF 78.39%)); 
  color: #FFF; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 
  white-space: nowrap;
  min-height: 52px;

  &:hover {
    background-color: #FFF; 
    color: #000; 
  }

  &:focus {
    outline: none; 
  }

  @media (max-width: 694px){
    width: 100%;
    margin-top: 1em;
  }

`;

export const StyledTdGroup = styled.td`
  text-align: center;
  @media (max-width: 640px){
    &::before{
      content: attr(data-group) ':  ';
    }
  }
  

`;


export const ListTable = styled(Table)`
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
  border-radius: 5px;
  background: #FFF;


  th, td {
    padding: 20px;
    color: #878B8E;
  }

  

  th{
    border-bottom: 3px solid #F1F1F1;
    margin:0 1em;
  }

  @media (max-width: 640px){
   
    background: none;

    tr{
      margin: 1em 0;
      border-radius: 1em;
      background-color: #FFF;
    }
    

    th{
      display: none;
    }

    td{
      gap: .5em;
      text-align: left;
      display: block;
    }

    td:nth-child(1)::before{
      content: '';
      margin: 0;
      border-bottom: 3px solid red;
    }

    td:nth-child(3){
      border:none;

    }

    td:nth-child(1){
      font-weight: 700;
      
    }

    ${StyledTdGroup}:nth-child(4)::before{
      content: '';
    }

    td::before{
      content: attr(data-cell) ' :  ';
      font-weight: 700;
      margin-right: .5em;
    }

    
  }

`;

export const StyledTh = styled.th`
  text-align: center;
  color: #3C4043;
  @media (max-width: 650px){
    

  }
`;



export const StyledTd = styled.td`
  text-align: center;
  width: 20%;
  border-bottom: "1px solid #D9D9D9";
  @media (max-width: 650px){
    width: 100%;

  }

`;

export const StyledTdQualitativeAnswer = styled.td`
  text-align: left;
  width: 60%;
  border-bottom: "1px solid red";
  @media (max-width: 650px){
    width: 100%;

  }

`;


export const StyledTdPhoto = styled.td`
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px){
    display: flex;
    flex-direction: column; 
    justify-content: center;
    margin: 0;
  }
  
`;

export const StyledTr = styled.tr`
  
  @media (max-width: 640px){
    border: 1px solid #c9c9c9;
  }
  
`

export const ContainerStyledPhoto = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;

  @media (max-width: 640px){
    justify-content: center;
    flex-direction: row;
  }

  @media (max-width: 1200px){
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

`;

export const StyledNameEmployee = styled.div`

  @media (max-width: 968px){
    text-align: start;
  }

`

// FAZ COM QUE ALTERNE A COR DE FUNDO DA FOTO DE PERFIL PADRÃO

const gradients = [
  'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',
  'linear-gradient(315deg, #AE2EFE 0%, #DDBEFF 100%)',
  'linear-gradient(314deg, #FA7F63 4.99%, #FEE073 100%)',
  'linear-gradient(315deg, #2B81FF 12.16%, #04D7FF 78.39%)',
];

const getRandomGradient = () => {
  const randomIndex = Math.floor(Math.random() * gradients.length);
  return gradients[randomIndex];
};

// FAZ COM QUE ALTERNE A COR DE FUNDO DA FOTO DE PERFIL PADRÃO

export const StyledPhoto = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: ${getRandomGradient()};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  color: #3C4043;
  @media (max-width: 1000px){
    display: flex;
    margin: 0;
  }

  @media (max-width: 1300px){
    display: none;

  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  
  @media (max-width: 768px){
    font-size: 18px;
  }

`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color:  #878B8E;
`;


export const StyledSelect = styled(Select)`
  border-radius: 10px;
  border: 3px solid #23233C;
  background: #2A2A48;
  color: white; 
  padding: 6px; 
  width: 100%;
  

`;

export const StyledAsyncSelect = styled(AsyncSelect)`
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #F1F1F1;
  color: #3C4043; 
  padding: 6px; 
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
`;

export const StyledAsyncSelectIndex = styled(StyledAsyncSelect)`
  background:  #F1F1F1;
  width: 100%;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

export const StyledAsyncSelectFilter = styled(AsyncSelect)`
  border-radius: 6px;
  background: #FFF;
  color: #3C4043; 
  padding: 6px;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 694px){
    width: 100%;
    display: flex; 
    justify-content: center;
    margin-top: 1em;
    background-color:#FFF;

    &:focus { 
    outline: none; 
    background: #F1F1F1; 
    }


    

  
    
  }

  @media (max-width: 1400px){
      font-size: .85em;

  
    
  }

  &::placeholder{
    color: #3C4043;
    text-align: center;
  }
`;

export const StyledSelectFilter = styled(Select)`
  border-radius: 6px;
  border: 1px solid #373956;
  background: var(--Surface, #373956);
  color: white; 
  padding: 6px; 
`;





export const GradientButton  = styled(Select)`
display: inline-block;
    border-radius: 4px;
    background: var(--Gradients-Green-Gradient, linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%));
    border-color: transparent;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 16px; // ajuste conforme necessário
    padding: 10px 20;
    cursor: pointer;
transition: all 0.3s ease;
&:hover {
  background: var(--Gradients-Green-Gradient, linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)) no-repeat;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
`;


export const InputConfig = styled(Input)`

  border-radius: 10px;
  background: #F1F1F1;
  color: #3C4043; 
  padding: 10px; 
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

  &:focus {
    outline: none; 
    background:  #F1F1F1; 
    border-color: #CCCCCF; 
  }
  &::placeholder { 
    opacity: 0.4  !important;
  }


  &::-webkit-input-placeholder {
    opacity: 0.4 !important;
  }


  &::-moz-placeholder {
    opacity: 0.4 !important;
  }


  &:-ms-input-placeholder { 
    opacity: 0.4 !important;
  }

`;

export const CardManagerConfig = styled.div`
  padding: .8em;
  border-radius: .6em;
  width: 100%;
  flex-grow: 3;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 900px){
    padding: .2em;
    margin:1em 0;
  }

   @media (min-width: 694px){
    padding: 1em;
   }

  @media (min-width: 768px){
    margin-top: 2em;

  }


`;

export const CardProfilePic = styled.div`
  padding: 2em;
  border-radius: .6em;
  width: 100%;
  margin-top: 1em;
  background-color: #FFF;
  
  @media (max-width: 768px){
    padding: 1em;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
  }

  @media (min-width: 769px){
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: 2em;
  }

  @media (max-width: 694px){
    background-color: #FFF;
  }

`

export const SaveButton = styled.button`
  padding: .7em 2em; 
  gap: 10px; 
  border: none; 
  border-radius: 5px; 
  background: linear-gradient(90deg, #1BD7F2 0%, #1AD9F2 105.53%); 
  color: #000000; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 

  &:hover {
    background-color: #FFFFFF; 
    color: #FFF; 
  }

  &:focus {
    outline: none; 
  }

`;



// divs da parte de usuarios do settings

export const ContainerButton =  styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px){
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerConfigUser = styled(Row)`
  gap: 1em;
  display: flex;
  flex-direction: row;
  @media (max-width: 694px){
    display: flex;
    flex-direction: column;

  }

  @media (min-width: 695px){
    display: grid;
    grid-template-columns: 1fr 1.5fr; /* Define as colunas com larguras flexíveis */
    justify-content: space-between;
  }

  @media (min-width: 1100px){
    display: grid;
    grid-template-columns: 1fr 1fr; /* Define as colunas com larguras flexíveis */
    justify-content: space-between;
  }
    
`

export const ContainerFilterButton = styled.div`
  display: flex;
  gap: 1em;
  justify-content: end;

  @media (max-width: 694px){
    flex-direction: column;

  }

`;
// divs da parte de usuarios do settings


// divs da parte geral do settings

export const Arrow = styled(FiChevronRight)`

  @media (max-width: 1100px){
    transform: rotate(90deg);
  
  }
`;


export const ContainerConfigGeneral = styled.div`
  display: grid;


  @media (min-width: 1101px){
   grid-template-columns: 25% 50% 25%;
   gap: 1em;

  }

  @media (max-width: 1100px){
    flex-direction: column;
    justify-content: center;
    
  }

  @media(min-width: 524px){
    margin: 0 2em;
  } 

  @media(min-width: 768px){
    margin: 0 3em;
  } 
  
`;


export const StyledCol = styled.div`
  width: 100%;
  @media (min-width: 1660px){
    width: 96%;
  }
`;


export const StyledColDetails = styled.div`
  width: 100%;
  @media (min-width: 1660px){
    width: 98%;
  }
`


export const StyledColProfile = styled.div`
  width: 100%;
  @media (min-width: 1660px){
    width: 80%;
  }
 
`
// divs da parte geral do settings

export const MenuButtonContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  padding: 1em;
  width: 100%;
  top: 0%;
  position: sticky;
  z-index: 1000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media (min-width: 1200px){
    display: none;
  }

`;


export const StyledTabContent = styled(TabContent)`
  @media (min-width: 770px){
    margin: 0 1em;
  }

  @media (max-width: 769px){
    padding: 0 em;
  }
`;


export const StyledTabPane = styled(TabPane)`
  display: flex;
  background: none;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerConfigGroup = styled(Row)`
  gap: 1em;
  display: flex;
  flex-direction: row;
  @media (max-width: 694px){
    display: flex;
    flex-direction: column;

  }

  @media (min-width: 695px){
    display: grid;
    grid-template-columns: 1fr 1.5fr; /* Define as colunas com larguras flexíveis */
    justify-content: space-between;
  }

  @media (min-width: 1100px){
    display: grid;
    grid-template-columns: 1fr 1fr; /* Define as colunas com larguras flexíveis */
    justify-content: space-between;
  }
    
`;

export const ContainerButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ButtonAddGroup = styled.div`
  display: flex;
  gap: 2em;
  @media (max-width: 694px){
    justify-content: center;
  }
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;


`;

export const ContainerEditGroup = styled.div`
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 635px){
    justify-content: center;
  }
`;