import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext, useRef } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { StyledAsyncSelect, StyledText, StyledInputText, ButtonAddGroup, StyledModalFooter } from '../styles'
import { Input } from 'reactstrap'
import { LabelInput, LabelNames } from '../../userRegister/style'
import { useToast } from '../../../context/Toast'
import { StyledModal } from '../modalUser/styles'
import debounce from 'debounce-promise'
import { getUsersByCompany } from '../../../services/user/user'
import { useAuth } from '../../../context/AuthContext'
import { Autocomplete, TextField } from '@mui/material'
import {Button} from '@mui/material'
interface IFormState {
  name: string,
  users: any,
}


function ModalAddGroup({
  show,
  onClose,
  addItem,
}: any) {

  const colourStyles: any = {
    control: (styles: any) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: undefined,
      boxShadow: 'none',
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected || isFocused
            ? '#CCCCCF'
            : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected || isFocused
            ? '#3C4043'
            : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected || isFocused)
            ? '#CCCCCF'
            : undefined,
        },
      };
    },
    singleValue: (styles: any) => ({
      ...styles,
      color: '#3C4043'
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      color: '#3C4043',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      display: 'none',

    }),
    input: (base: any) => ({
      ...base,
      color: 'white',
      width: '100%',

    }),
  };

  const { showToast } = useToast()
  const { user } = useAuth()
  const [showLoading, setShowLoading] = useState(false)

  const [form, setForm] = useState<IFormState>({
    name: '',
    users: '',
  });

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const loadAsyncOptions = async () => {
      const result = await debounceOptionsUsers();
      setOptions(result);
    };

    loadAsyncOptions();
  }, []);


  const onSubmit = async () => {

    const { name, users } = form;
    if (!name) {

      return showToast('Preencha o campo', 'error')

    }
    const company = user.user?.company_id
    if(!company) 
      throw new Error("company_not_found")

    const dataToSend= {...form, company_id: company}
    if(users) 
      dataToSend.users =  [form.users.value][0];
    
   
    
    try {
      setShowLoading(true)
      await addItem(dataToSend)
      setForm({
        name: '',
        users: '',
      })
      showToast('Grupo cadastrado', 'success')
      onClose()
    } catch (error) {
      console.log(error)
      showToast('Falha ao cadastrar grupo', 'error')
    }

    setShowLoading(false)
  }

  const loadUserInputOptions = async (term: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let options: any = []
        const company = user.user?.company_id
        if(!company) throw new Error("company_not_found")
        let response: any = await getUsersByCompany({ company_id: company }, 1)

        if (!response || response?.data?.length <= 0) return resolve(options)

        response?.data?.forEach((element: any) => {
          options.push({
            label: element?.first_name + ' ' + element?.last_name,
            value: element.id
          })
        })

        return resolve(options)
      } catch (e) {
        console.error(e)
        return reject([])
      }
    })
  }

  const debounceOptionsUsers: any = debounce(loadUserInputOptions, 600)



  if (!show) return null
  return (
    <>
      <StyledModal show={show} size="lg" >
        <Modal.Header style={{background:'#FFF'}}>
          <Modal.Title style={{color:'#3C4043'}}>Adicionar grupo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background:'#FFF', border:'none'}}>

          <Row>
            <Col xs={12} sm={12}>
              <Form.Group  className="mb-3 mt-3" controlId="name">
                {/* <LabelNames style={{ color: '#3C4043' }}>Nome</LabelNames> */}
                <TextField
                  label="Nome do grupo"
                  placeholder="Digite o nome do grupo"
                  sx={{
                    width: '100%',
                    color: '#3C4043', //cor do texto dentro do input
                    backgroundColor: '#F1F1F1',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor:'#c9c9c9',
                      borderRadius:'6px',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)', 
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9', 
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9',
                    },
                    '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9',
                    },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9',
                    },
                  }}
                  onChange={(e: any) => {
                    setForm({
                      ...form,
                      ['name']: e.target.value
                    });
                  }}
                />
                {/* <StyledInputText style={{ background: '#F1F1F1 ', paddingRight: 10 }}
                  type="text"
                  name="name"
                  onChange={(e: any) => {
                    setForm({
                      ...form,
                      ['name']: e.target.value
                    });
                  }}
                /> */}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12}>
              <Form.Group className="mb-3 mt-4" controlId="users">
                {/* <LabelNames style={{color: '#3C4043' }}>Usuário</LabelNames>
                <StyledAsyncSelect
                  value={form?.users}
                  placeholder=''
                  name="users"
                  defaultOptions
                  loadOptions={debounceOptionsUsers}
                  styles={colourStyles}
                  noOptionsMessage={() => 'Sem opção'}
                  onChange={(event: any) => {
                    setForm({
                      ...form,
                      ['users']: event
                    })
                  }}
                /> */}
                {/* <Autocomplete
                  sx={{
                    backgroundColor: '#F1F1F1',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor:'#c9c9c9',
                      borderRadius:'6px',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)', 
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9', 
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9',
                    },
                    '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c9c9c9',
                    },
                }}
                  multiple={true}
                  options={options}
                  onChange={(event, newValue) => {
                    setForm({
                      ...form,
                      ['users']: newValue
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Adicionar membros"
                      placeholder="Digite o nome do membro"
                    />
                  )}
                /> */}

              </Form.Group>
            </Col>

          </Row>


        </Modal.Body>
        <StyledModalFooter style={{background:'#FFF'}}>
          <ButtonAddGroup >
            <Button
              sx={{
                textTransform:'capitalize',
                borderRadius: '2em',
                fontSize: '1em',
                fontWeight: 500,
              }}
              
              variant='text'
              onClick={onClose}
              disabled={showLoading}
            >
              Fechar
            </Button>
          

            <Button
              variant='contained'
              sx={{
                textTransform:'capitalize',
                background:'#2491FF',
                borderRadius: '2em',
                fontSize: '1em',
                fontWeight: 'semibold ' ,
              }}
              onClick={() => {
                onSubmit();
              }}
              disabled={showLoading}
            >
              {showLoading ? 'Confirmando...' : 'Confirmar'}
            </Button>
          </ButtonAddGroup>
          
        </StyledModalFooter>
      </StyledModal>
    </>
  )
}

export default ModalAddGroup
