import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCardTitle = styled(Card.Title)`
  color: #3C4043;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: .5em;
  @media(max-width: 694px){
    font-size: 24px;
  }
  @media(min-width: 695px){
    font-size: 32px;
  }

  @media(min-width: 992px){
    font-size: 36px;
  }  

`;


export const StyledCardTitleEmployee = styled(Card.Title)`
  color: #FFF;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: .5em;
  @media(max-width: 694px){
    font-size: 24px;
  }
  @media(min-width: 695px){
    font-size: 32px;
  }

  @media(min-width: 992px){
    font-size: 36px;
  }  

`;

export const StyledCardSubtitle = styled(Card.Subtitle)`
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1em;
  @media(max-width: 694px){
    font-size: 18px;
  }

  @media(max-width: 768px){
    padding: 0em;
    
  }

  @media(min-width: 695px){
    font-size: 20px;
  }

  @media(min-width: 1200px){
    font-size: 24px;
  }
`;

export const StyledCardText = styled(Card.Text)`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1em .1em;
  opacity: 0.7;

  
  /* MOBILE */
  @media(max-width: 694px){
    font-size: 16px;
    padding: 0 ;
    max-width: 444px;
  }
  /* MOBILE */
  @media(min-width: 650px){
    padding: 0 2em;
  }

  @media (min-width: 1200px){
    padding: 0 3em;
    font-size: 20px;
    
  }

  @media (min-width: 1440px){
    padding: 0 3em;
   
    
  }
`;

export const  InsideCardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

 @media (min-width: 1200px){
    margin-bottom: 0 em;
    
  }

  @media(max-width: 694px){
    box-shadow: none;
  }


`;


export const CardInicio = styled.div`
  background-color: #FFF;
  margin-left: 1.5em;
  border-radius: 30px; 
  padding: 1.5em;
  box-shadow: 0px 3.162px 6.325px 3.162px rgba(0, 0, 0, 0.20); 
  position: relative;
  text-align: center;

  /* MOBILE */
  @media(max-width: 694px){
    box-shadow: none;
    border: none;
    padding: .1em;
  }

  @media(min-width: 768px) and (max-width: 992px){
    padding: 2em;
    
  }
  /* MOBILE */

  @media (min-width: 1200px){

    
  }

  @media (min-width: 1440px){
    margin: 0 2em;
    padding: 1em 2em 1.5em 2em;
    min-width: 586px;
    
    
  }

  @media (min-width: 1660px){
    margin: 0 8em;
    padding: 3em;
    
  }  
`;

export const CardEmployee = styled.div`
  background-color: #373956;
  margin-left: 1.5em;
  border-radius: 30px; 
  padding: 1.5em;
  box-shadow: 0px 3.162px 6.325px 3.162px rgba(0, 0, 0, 0.20); 
  position: relative;
  text-align: center;

  /* MOBILE */
  @media(max-width: 694px){
    box-shadow: none;
    border: none;
    padding: .1em;
  }

  @media(min-width: 768px) and (max-width: 992px){
    padding: 2em;
    
  }
  /* MOBILE */

  @media (min-width: 1200px){

    
  }

  @media (min-width: 1440px){
    margin: 0 2em;
    padding: 1em 2em 1.5em 2em;
    min-width: 586px;
    
    
  }

  @media (min-width: 1660px){
    margin: 0 8em;
    padding: 3em;
    
  }  
`;

export const StartButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px; 
  gap: 10px; 
  border: none; 
  border-radius: 5px; 
  background: linear-gradient(90deg, #1BD7F2 0%, #1AD9F2 105.53%); 
  color: #3C4043; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s;
  margin: 1em 0;
  font-weight: 500;

  @media(max-width: 768px){
    margin: 1em 0;
  }

  &:hover {
    background-color: #FFFFFF; 
    color: #FFFFFF; 
  }

  &:focus {
    outline: none; 
  }
`;


export const FooterContainer = styled.footer`
  padding: .8em;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: .5em;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  
`;

export const TextFooter = styled.p`
  font-style: italic;
  font-size: 12px;
  margin: 0;
`
