import React, { createContext, useEffect, useState, ReactNode, FC, useContext } from 'react'
import { useToast } from './Toast';


interface AuthContextType {
	user: {
		user: any;
		verifyToken: any
		confirmationCode:any
	};
	saveDataSignUpOnStorage: any;
	SignOut: any;
	saveDataVerifyOnStorage: any;
	saveRetryingFlagOnStorage: any;
	deleteRetryingFlagOnStorage: any;
}


export const AuthContext = createContext<AuthContextType>({
	user: {
		user: {},
		verifyToken: null,
		confirmationCode:null,
	},
	saveDataSignUpOnStorage: () => { },
	SignOut: () => { },
	saveDataVerifyOnStorage: () => { },
	saveRetryingFlagOnStorage: () => { },
	deleteRetryingFlagOnStorage: () => { }
})

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState({
		user: {},
		verifyToken: null,
		confirmationCode:null,
	})
	
	const { showToast } = useToast()

	useEffect(() => {
		const authUser = localStorage.getItem('zenbox.identity')
		let refreshState = { ...user }
		if (authUser && JSON.parse(authUser)) {
			let dataUser = JSON.parse(authUser)

			if (dataUser?.user) {
				refreshState.user = { ...dataUser.user }

			}
		}

		const verifyUser = localStorage.getItem('zenbox.verify')
		if (verifyUser && JSON.parse(verifyUser)) {
			let dataVerify = JSON.parse(verifyUser)
			refreshState.verifyToken = dataVerify.verifyToken
			refreshState.confirmationCode = dataVerify.confirmationCode
		}

		setUser(refreshState)
	}, [])

	const saveDataSignUpOnStorage = (response: any) => {
		setUser(prevUser => ({
			...prevUser,
			user: response
		  }));


		localStorage.setItem(
			'zenbox.identity',
			JSON.stringify({
				user: { ...response },
			})
		)

	}

	const saveDataVerifyOnStorage = (response: any) => {
		setUser(prevUser => ({
			...prevUser,
			verifyToken: response?.verifyToken,
			confirmationCode: response?.confirmationCode,
			user: response?.user ? { ...response.user } : {}
		  }));
		localStorage.setItem(
			'zenbox.verify',
			JSON.stringify({
				verifyToken: response.verifyToken,
				confirmationCode: response?.confirmationCode
			})
		)

		localStorage.setItem(
			'zenbox.identity',
			JSON.stringify({
				user: { ...response.user },
			})
		)

	}

	const saveRetryingFlagOnStorage = (response: {isRetrying: boolean}) => {
		localStorage.setItem(
			'zenbox.retryingFlag',
			JSON.stringify({
				isRetrying: response.isRetrying,
			})
		)
	}

	const deleteRetryingFlagOnStorage = () => {
		localStorage.removeItem('zenbox.retryingFlag');		
	}

	const SignOut = (destiny: string = '/signup') => {
		localStorage.removeItem('zenbox.identity')
		localStorage.removeItem('zenbox.verify')
		localStorage.removeItem('zenbox.question')

		showToast('Sua sessão foi encerrada!', 'info', 800)
		setTimeout( () => {
			window.location.href = destiny
			setUser({
				user: {},
				verifyToken: null,
				confirmationCode:null,
			})
		}, 1000);
		
	}



	return <AuthContext.Provider value={{ user, SignOut, saveDataSignUpOnStorage, saveDataVerifyOnStorage, saveRetryingFlagOnStorage, deleteRetryingFlagOnStorage }}>{children}</AuthContext.Provider>
}

export function useAuth() {
	const context = useContext(AuthContext)
	return context
}


