import React, { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import { StyledContainerCompany } from './styles';


interface ICompanyNameItemProps{
    companyName: string;
    onClick: () => void;
}

const CompanyNameItem: React.FC<ICompanyNameItemProps> = ({companyName, onClick}) => {
    return(
        <div onClick={onClick}>
            <StyledContainerCompany>
                <MenuItem sx={{ 
                    display: 'flex',
                    gap: '1em',
                    alignItems: 'center',
                    padding: '1em'
                }}>
                    <DomainRoundedIcon fontSize='small'/>
                    {companyName}
                </MenuItem>
            </StyledContainerCompany>
        </div> 
    )

}

export default CompanyNameItem;