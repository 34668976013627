import { createTheme } from "@mui/material"

export const themeLight = createTheme({
    palette: {
        primary: { //usado em cores com mais destaque
            main: '#3C4043', //principal
            dark: '', // como se fosse o hover
            light: '#878B8E', 
            contrastText: '#F0F4F9', //fazer contraste com a cor primaria
        },
    },

    typography: {
      fontFamily: '"Product Sans", sans-serif',

    },

    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: ".9em", // Altere o valor conforme necessário
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#c9c9c9',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#c9c9c9',
            },
          },
          notchedOutline: {
            borderColor: 'transparent',
          },
        },
      },
    },

})