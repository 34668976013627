import { endOfMonth, getDate } from "date-fns"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import CustomCard from "../../components/customCard"
import Header from "../../components/header"
import { useAuth } from "../../context/AuthContext"
import { useQuestions } from "../../context/QuestionContext"
import { useToast } from "../../context/Toast"
import { getCompanyId } from "../../services/company/company"
import { getUserByEmail } from "../../services/user/user"
import { InsideCardStyles, StartButton, StyledCardSubtitle, StyledCardText, StyledCardTitle, CardEmployee, StyledCardTitleEmployee } from "./styles"


const WelcomeWebSummit: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, saveDataVerifyOnStorage, saveDataSignUpOnStorage, SignOut } = useAuth()
  const { answerId } = useQuestions()
  const [urlParam, setToken] = useState({
    token: null,
    email: null,
  })
  const { showToast } = useToast()

  useEffect(() => {

    // const getTokenFromUrl = async () => {
    //   const queryParams = new URLSearchParams(location.search)
    //   const url: any = {}
    //   if (queryParams.get('token')) url.verifyToken = queryParams.get('token')
    //   if (queryParams.get('email')) url.user = {
    //     email: queryParams.get('email'),
    //   }
    //   if (queryParams.get('confirmation_code')) {
    //     url.confirmationCode = queryParams.get('confirmation_code')
    //   } else {
    //     delete url.confirmationCode;
    //   }
    //   if (Object.keys(url).length !== 0) {
    //     saveDataVerifyOnStorage(url)
    //     if (queryParams.get('email')) {
    //       let result = null
    //       try {
    //         result = await getUserByEmail(queryParams.get('email'))
    //       } catch (error) {
    //         console.warn(error);
    //       }
    //       const dataToSave = {
    //         id: result?.id,
    //         email: result?.email,
    //         status: result?.status,
    //         first_name: result?.first_name,
    //         last_name: result?.last_name,
    //         company_id: result?.company?.Item?.id,
    //         company_name: result?.company?.Item?.name_company,
    //         role: result?.role,
    //       }
    //       saveDataSignUpOnStorage(dataToSave)
    //     }
    //   }
    // }
    // getTokenFromUrl()

  }, [location])



  return (

    <>
      <Header />

      <Container fluid className="d-flex h-100 mt-5 mb-2">
        <Row className="justify-content-center align-self-center w-100">
          <Col md={9} lg={7}>
            <CardEmployee>
              <InsideCardStyles className=' d-flex flex-column align-items-center'>

                <StyledCardTitleEmployee>Como está a sua <br />felicidade no trabalho?</StyledCardTitleEmployee>
                <StyledCardSubtitle className='mt-4'>Sua participação é muito importante!</StyledCardSubtitle>
                <div className="d-flex align-items-center justify-content-center" style={{

                  marginBottom: 20,
                }}>
                  <StyledCardText>
                  A seguir você encontrará algumas perguntas sobre aspectos da relação que você tem com seu ambiente de trabalho, com você mesmo, com as pessoas a sua volta.<br /><br /><br />Vamos lá?
                  </StyledCardText>
                </div>
                <StartButton onClick={async () => {
                  if (user.verifyToken) {
                    navigate('/self-signup')
                  } else {
                    navigate('/signup')
                  }
                }} >Começar</StartButton>
              </InsideCardStyles>
            </CardEmployee>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WelcomeWebSummit;

