import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface PaginationProps {
	data: {
		total_items: number
		total_pages: number
		current_page: number
		data: any[]
	}
	handlePageChange: (pageNumber: number) => void
}

const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 8px;
`

const PaginationItem = styled(({ active, ...props }: { active?: boolean, [key: string]: any }) => <div {...props} />)<{ active?: boolean }>`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => (props.active ? '#CCCCCF' : '')};
    color: ${props => (props.active ? '#878B8E' : '#CCCCCF')};
    margin: 0 5px;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCF;
        color: #878B8E;
    }
`

const PaginationControl = styled.div`
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	cursor: pointer;
	color: #CCCCCF;

	&:hover {
		background-color: #CCCCCF;
		color: #878B8E;
	}
`

const CustomPagination: React.FC<PaginationProps> = ({
	data,
	handlePageChange: handlePageChangeProp
}) => {
	const { total_pages, current_page } = data

	const [firstPage, setFirstPage] = useState(1)
	const pagesToShow = 5

	useEffect(() => {
		if (current_page < firstPage || current_page >= firstPage + pagesToShow) {
			setFirstPage(current_page)
		}
	}, [current_page])

	const handlePageChange = (pageNumber: number) => {
		handlePageChangeProp(pageNumber)
	}

	const nextPage = () => {
		handlePageChange(Math.min(total_pages, current_page + 1))
	}

	const prevPage = () => {
		handlePageChange(Math.max(1, current_page - 1))
	}

	const paginationItems = []
	for (
		let number = firstPage;
		number < firstPage + pagesToShow && number <= total_pages;
		number++
	) {
		paginationItems.push(
			<PaginationItem
				key={number}
				active={number === current_page}
				onClick={() => handlePageChange(number)}
			>
				{number}
			</PaginationItem>
		)
	}

	return (
		<PaginationContainer>
			{current_page > 1 && (
				<>
					<PaginationControl onClick={() => handlePageChange(1)}>
						{'<<'}
					</PaginationControl>
					<PaginationControl onClick={prevPage}>{'<'}</PaginationControl>
				</>
			)}
			{paginationItems}
			{current_page < total_pages && (
				<>
					<PaginationControl onClick={nextPage}>{'>'}</PaginationControl>
					<PaginationControl onClick={() => handlePageChange(total_pages)}>
						{'>>'}
					</PaginationControl>
				</>
			)}
		</PaginationContainer>
	)
}

export default CustomPagination
