import React from 'react';
import { Doughnut } from 'react-chartjs-2';


interface HalfDoughnutChartProps {
  color?: string;
  background?: string;
  target: number;
  reached: number;
}

const HalfDoughnutChart: React.FC<HalfDoughnutChartProps> = ({
  color,
  target,
  background,
  reached
}) => {
  const options: any = {
    rotation: -90,
    circumference: 180,
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1, 
    cutout: '90%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, 
      }
    },
  };

  const data = {
    labels: [],
    datasets: [
      {
        data: [reached, target - reached],
        backgroundColor: [color, 'rgba(202, 203, 218, 1)'],
        background: [background, 'rgba(202, 203, 218, 1)'],
        borderColor: 'rgba(202, 203, 218, 1)', 
        borderWidth: 1,
        borderRadius: 3,
      },
    ],
  };

  return (
  <div style={{ height: '9em', width:'80%' }}>
    <Doughnut data={data} options={options} />
     </div>
  )
};

export default HalfDoughnutChart;
