import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../../components/header"
import { Col, Container, Row, Spinner } from "react-bootstrap"
import { CardEmployee } from "../welcome/styles"
import { ContainerSignUp, ContainerSignUpAdmin, ContainerSignUpButtons, StyledSignUpText } from "../../components/renderField/styles"
import { StyledBackButton, StyledBackButtonAdmin, StyledConfirmationCodeText, StyledContainerButtons, StyledContainerLine, StyledContainerParagraph, StyledLoginButton, StyledParagraph, StyledParagraphConfirmationCode, StyledSwitchButton } from "../signup/styles"
import VerificationInput from "react-verification-input"
import './styles.css'
import { useToast } from "../../context/Toast"
import CustomCard from "../../components/customCard"

const ConfirmationCodeAuthAdmin : React.FC = () =>{

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const {showToast} = useToast()
    const [savedValue, setSavedValue] = useState('')

    const [isAdminPage, setIsAdminPage] = useState(false);

    useEffect(() => {
        setIsAdminPage(window.location.pathname.includes('admin'));
    }, []);


    const goToSurvey = () =>{
        if(savedValue != ''){
            navigate('/admin')
            showToast('O USUARIO SERIA LEVADO PARA O PAINEL ADMIN', 'info', 100000)
        } else {
            showToast('Ocorreu um erro', 'error', 1000)
        }
    }
   
    return(
        <>
            <Container fluid className="d-flex h-100 mt-5 mb-2">
                <Row className="justify-content-center align-self-center w-100">
                    <Col md={9} lg={7}>
                        <CustomCard>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{
                                    minHeight: '300px',

                                }}>
                                    <Spinner style={{ color: '#c9c9c9' }}></Spinner>
                                </div>
                            ) : (
                                <ContainerSignUpAdmin>
                                    <div className="d-flex justify-content-center flex-column ">
                                        <StyledConfirmationCodeText style={{color: '#3C4043'}}>Enviamos um SMS para seu número com um código de acesso</StyledConfirmationCodeText>
                                        <StyledParagraphConfirmationCode style={{color: '#3C4043'}}>Preencha abaixo com o código: </StyledParagraphConfirmationCode>
                                        <div className="d-flex justify-content-center" style={{
                                            width: '100%',
                                            flexDirection: 'column',
                                        }}>
                                        <VerificationInput 
                                            length={6} 
                                            validChars="0-9"
                                            value={savedValue}
                                            onChange={setSavedValue}
                                            inputProps={{ inputMode: "numeric" }}
                                            placeholder=""
                                            classNames={{
                                                container: "container-admin",
                                                character: "character-admin",
                                                // characterInactive: "character--inactive",
                                                // characterSelected: "character--selected",
                                                // characterFilled: "character--filled",
                                            }}
                                            
                                        />

                                        </div>
                                    </div>
                                    <StyledContainerParagraph>
                                    
                                    </StyledContainerParagraph>

                                    <StyledSwitchButton
                                        style={{color: '#3C4043'}} 
                                        onClick={() => {
                                            showToast('Enviamos um novo código para o seu numero', 'success', 6000)
                                            
                                        }}
                                    >
                                        Não recebeu nosso cóldigo? Clique aqui para reenviar o SMS
                                    </StyledSwitchButton>
                                    <ContainerSignUpButtons>
                                        <StyledContainerButtons>
                                            <StyledBackButtonAdmin onClick={() => { navigate('/admin/signup') }} >Voltar</StyledBackButtonAdmin>
                                            
                                            <StyledLoginButton onClick={goToSurvey}> Fazer Login</StyledLoginButton>
                                        </StyledContainerButtons>
                                        
                                    </ContainerSignUpButtons>
                                </ContainerSignUpAdmin>
                            )}
                        </CustomCard>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ConfirmationCodeAuthAdmin;