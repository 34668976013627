import { CardFooter } from "react-bootstrap";
import styled from "styled-components"
import { Card, CardBody, Col, Row } from "react-bootstrap"




export const CardDimension = styled(Card)`
    color: #3C4043;
    background: transparent;
    border:none;
    

`

export const StyledHeaderDimension = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F1F1F1;
    border-radius: 10px 10px 0 0;
    border-bottom: 8px solid #F5F7F8;
    width: 100%;
    
    @media (max-width: 460px){
       height: 8vh;
       
    }

    @media (min-width: 461px) and (max-width: 768px){
        height: 7vh;
    }

    @media (min-width: 769px) and (max-width: 1000px){
        height: 7vh;
        
    }

    @media (min-width: 1001px) and (max-width: 1368px){
        height: 9vh;
        
    }

    @media (min-width: 1369px) and (max-width: 1440px){
        height: 9vh;
        
    }

`;

export const StyledNumberColor = styled.div`
    border-left: 8px solid #F5F7F8;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    gap: .5em;
    padding: 1.5em 1em 1.5em 1em;
    min-width: 145px;
    height: 11vh;
    @media (max-width: 460px){
        min-width: 128px;
        height: 10vh;
    }

    @media (min-width: 461px) and (max-width: 768px){
        min-width: 128px;
        height: 13vh;
    }

    @media (min-width: 769px) and (max-width: 1368px){
        
        height: 13vh;
    }


`;

export const ContainerDimension =  styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
 

    @media (min-width: 0px) and(max-width: 375px){
        
    }
    
    @media (min-width: 376px) and(max-width: 600px){
        
    }


    /* @media(max-width: 600px){
        width: 30%;
    } */


`;

export const StyledTitle = styled.p`
    font-size: 20px;
    font-weight: 700;
    margin-left: .5em;
    margin-bottom: 0;
    color: #3C4043;
    text-align: left;

    @media (max-width: 460px){
        font-size: 16px
    }

    @media (min-width: 461px) and (max-width: 768px){
        font-size: 18px;
        margin-bottom: 0;
    }


`


export const StyledSubtitle = styled.span`
    color: #878B8E;
    font-size: 16px;
    font-weight: 400;
    margin-left: .1em;
    
    margin-bottom: 0;
    @media(max-width: 768px){
        font-size: 14px;
        margin-bottom: 0;
    }

    @media(min-width: 1470px){
        font-size: 18px;
        margin-bottom: 0;
    }

`

export const StyledSubtitleNumberDimension = styled.span`
    color: #878B8E;
    font-size: 16px;
    font-weight: 400;
    margin-left: .1em;
    min-width: 35px;
    margin-bottom: 0;
    @media(max-width: 768px){
        font-size: 14px;
        margin-bottom: 0;
    }

    @media(min-width: 1470px){
        font-size: 18px;
        margin-bottom: 0;
        min-width: 38px;
    }

`




export const StyledAccordionOpen = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    /* padding: 1em 1em 1em 2em;    */

    @media (max-width: 460px){
       height: 10vh;
       
    }

    @media (min-width: 461px) and (max-width: 768px){
        height: 10vh;
    }

    @media (min-width: 769px) and (max-width: 1000px){
        height: 9vh;
        
    }

`;

export const ContainerNumberAndQuestion = styled.div`
    display: flex;
    align-items: center;
    color:#878B8E;
    gap: 1.5em;
    padding: 1em 1em 1em 2em;

     @media (min-width: 320px) and (max-width: 460px){
       padding: 1em 1em 1em .8em;
       gap:.5em
       
    }

    @media (min-width: 461px) and (max-width: 768px){
        font-size: 18px;
        margin-bottom: 0;
        gap: 1em;
    }

    @media (min-width: 769px) and (max-width: 1000px){
        font-size: 18px;
        margin-bottom: 0;
        gap: 1em;
        padding: 1em 1em 1em 1.5em;
    }
`;

export const CircleColor = styled.div`
    border-radius: 50%;
    overflow: hidden;
    width: 20px; 
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    min-width: 12px; 
    min-height: 12px;
    max-height: 12px;
    max-width: 12px;

    @media (max-width:768px){
        min-width: 10px;
        min-height: 10px;
        max-height: 10px;
        max-width: 10px;   
    }

`;

export const NumberIfozDimension = styled.p`
    color:#878B8E; 
    font-weight:700;
    margin: 0;
    min-width: 54px;
 

    text-align: center;
    
    @media (min-width: 320px) and (max-width: 460px){
        font-size: 14px
    }

    @media (min-width: 461px) and (max-width: 768px){
        font-size: 16px;
        margin-bottom: 0;
    }

    @media (min-width: 1470px)  {
        font-size: 18px;
        min-width: 60px;
    }
`;


export const ContainerHeaderDimensionIFOZ = styled.div`
    display: flex;
    align-items: center;
    min-width: 145px;
    border-left: 8px solid #F5F7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    gap: .5em;
    padding: 1.5em;

    @media (max-width: 460px){
        min-width: 110px;
    }

    @media (min-width: 461px) and (max-width: 768px){
        min-width: 125px;
    }

`;

export const ContainerDimensionName = styled.div`
    padding: 1em 1em 1em .8em;
    display: flex;
    align-items: center;

`;


export const ContainerFilterAndGeneral = styled.div`
    display: flex; 
    justify-content: flex-end; 
    align-items: center; 
    margin-right: 2.5em;

    @media (max-width: 768px){
        margin-right: 1.4em;
    }

`;

export const ContainerFlexFilterAndGeneral = styled.div`
    display: flex;
    gap: 6em;
    @media (min-width: 460px) and (max-width: 768px){
        gap: 5em;
    }
    @media (max-width: 459px){
        gap: 3.5em;
    }
`;

export const ParagraphFilter = styled.p`
    color: #A5A5AA; 
    margin: 0;
    @media (max-width: 768px){
      padding-right: 1em;
    }

`;

export const ParagraphGeneral = styled.p`
    color: #A5A5AA; 
    margin: 0;
    @media (max-width: 768px){
        margin-right: 1em;
    }

`;




