import { CardFooter } from "react-bootstrap";
import styled from "styled-components"
import { Card, CardBody, Col, Row } from "react-bootstrap"
export {}




export const CardAttentionPoints = styled.div`
    background-color: #FFF;
    color: #3C4043;

`
export const CardAttentionPointsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;


`

export const CardAttentionPointsContent = styled.div`
    width: 100%;
    display: flex;
    gap: 1.5em;
`;

export const StyledCardHeader = styled(Card.Header)`
    border-bottom: 2px solid #F5F7F8 ;
    background-color: #FFF;
    border-radius: 1em 1em 0 0 ;

    @media(max-width: 694px){ 
        background-color: #FFF;
        border-bottom: 2px solid #F0F0F4;
    }
    

`

export const StyledCardBody = styled(Card)`
    margin-top: 1em;
    color: #FFF;
    border: none;
    border-radius: .7em;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    @media(max-width: 694px){ 

        border-top: none;
    }
    

`


export const StyledCardBodyOverflow = styled(Card)`
    margin-top: 1em;
    color: #FFF;
    border: none;
    border-radius: .7em;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        cursor: pointer;
        height: 8px ;
        border-radius: 1em;
        background-color:#F1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #c9c9c9 !important;
        padding: .2em !important;
        border-radius: 1em !important;
        cursor: pointer;
    }

    @media(max-width: 694px){ 
        border-top: none;
    }
`;


export const StyledRowOverflow = styled(Row)`
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        cursor: pointer;
        height: 8px ;
        border-radius: 1em;
        background-color:#F1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #c9c9c9 !important;
        padding: .2em !important;
        border-radius: 1em !important;
        cursor: pointer;
    }


`;

