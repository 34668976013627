import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const AutoSubscriptionMiddleware = ({  render, isAutoSub }:any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, saveDataVerifyOnStorage, saveDataSignUpOnStorage } = useAuth()

  useEffect(() => {
    // console.log("Passou pelo Middleware")

    const params = new URLSearchParams(location.search);
    const refer = params.get('refer');
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');

    if (
      refer === 'WebSummitRio2024' &&
      utmSource === 'events' &&
      utmMedium === 'shirt' &&
      utmCampaign === 'websummitrio2024'
    ) {
      // console.log('Usuário acessou a rota do Web Summit Rio 2024');
      
      // Reseta a sessão do usuário
      localStorage.removeItem('zenbox.identity');
      localStorage.removeItem('zenbox.verify');
      localStorage.removeItem('zenbox.question');

      // Salva o Token Especial de Auto-registro como o token do usuário
      let autoSignupToken = process.env.REACT_APP_AUTO_SIGNUP_TOKEN
      saveDataVerifyOnStorage({verifyToken: autoSignupToken})

      const dataToSave = {
        company_id: process.env.REACT_APP_AUTO_SIGNUP_ORG_ID,
        company_name: "Web Summit Rio 2024",
        role: 3,
      }
      saveDataSignUpOnStorage(dataToSave)

      localStorage.setItem('zenbox.websummitrio2024', JSON.stringify({foo: "bar"}))
      isAutoSub(true);
    } else {
      // console.log("O usuário NÃO veio da url do Web Summit Rio 2024")
      navigate('welcome')
    }
  }, [location.search]);
  
  return render()
};

export default AutoSubscriptionMiddleware;