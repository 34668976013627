import styled from "styled-components";

export const CardDimension = styled.div`
    background-color: #ffffff;
    border-radius: 1em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: .5em 1em;
    margin-bottom: 16px;
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1146px) {
        min-height: 58px;
    }

    
`;

export const CardDimensionContent = styled.p`
    font-size: 14px;
    color: #3C4043;
    font-weight: 700;
    margin: 0;
    text-align: center;
    @media (max-width: 695px) {
        display: none;
    }
`;


export const ContainerCardDimension = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 0 16px 0 60px;
    gap: 1em;
    align-items: center;
   
`;


export const CardDimensionContentResponsive = styled.p`
    @media (min-width: 695px) {
        display: none;
    }
    margin: 0;
    text-align: center;

`;

export const LineContainer = styled.div`
    width: 1px;
    background-color: red;
    position: absolute;
    top: 8%;
    left: 300px;
    height: 283px;

`;

export const Gradientline = styled.div`
    background: "linear-gradient(to bottom, #00920F, #00FF19, #ECFF20, #F1C118, #F00)";
    width: 5px;
    height: 283px;
    position: absolute;
    top: 8%;
    left: 51px;
`;
