import styled from "styled-components";


export const ContainerManagerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 2em;
    background-color: #FFF;
    
    width: 100%;

    @media (max-width: 1199px){
        background: none;
        display: none;
    }
    @media (min-width: 1200px){
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    }
`



export const ContainerNameManagerHeader = styled.div`
    display: flex;
    align-items: center;
    color: #3C4043;
    @media(max-width: 1200px){
        display: none;
    }


`;


export const StyledContainerTutorial = styled.div`
    background: linear-gradient(315deg, #2B81FF 12.16%, #2491FF 24.23%, #04D7FF 78.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
`

export const StyledH5 = styled.h5`

    @media(max-width: 768px){
        font-size: 16px;
    }
`

export const StyledImg = styled.img`
    

    @media(max-width: 768px){
        height: 16px;
        width: 16px;
    }

    @media(min-width: 769px){

        height: 20px;
        width: 20px;
    }
`