import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Container } from 'react-bootstrap';
import { ContainerNameManagerHeader, ContainerManagerHeader, StyledContainerTutorial, StyledH5, StyledImg } from './styles';
import FileText from '../../assets/file-text-colored.svg'
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import ChangeCompanyDialog from './dialogModal/dialogModal';

export const iconPaths: any = {
  bell: require('../../assets/bell.png'),
  info: require('../../assets/info.png'),
};





const HeaderManager: React.FC<any> = ({ title }) => {
  const { user, SignOut } = useAuth()

  const getInitials = (name: string, surname: string) => {
    if (!name || !surname) return ''
    const firstInitial = name[0] ? name[0].toUpperCase() : '';
    const secondInitial = surname[0] ? surname[0].toUpperCase() : '';
    return firstInitial + secondInitial;
  }
  

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutButton = () => {
    SignOut('/admin/signup');
    setAnchorEl(null);
  };

  const [showDialogModal, setShowDialogModal] = useState(false)

  return (
    
    <ContainerManagerHeader>
      <div>
        <h3 style={{ color: '#3C4043', fontWeight:'700' }}>{title}</h3>

        <StyledContainerTutorial style={{display:'flex', justifyContent:'flex-start'}}>
          <a href="https://zenboxsupport.zendesk.com/hc/pt-br" target="_blank" rel="noopener noreferrer"><StyledImg src={FileText} draggable ="false" alt="Logo" style={{width: '20px', height: '20px'}} /></a>
          <StyledH5><a href="https://zenboxsupport.zendesk.com/hc/pt-br" target="_blank" rel="noopener noreferrer">Tutorial</a></StyledH5>
        </StyledContainerTutorial>
       
      </div>
      



      <ContainerNameManagerHeader>


        
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '22px',
        }}>
          <span style={{ fontSize: '16px', textAlign: 'right' , fontWeight: '700' }}>{user.user?.first_name} {user.user?.last_name}</span>
          <span style={{ fontSize: '16px', textAlign: 'right' }}>{user?.user?.company_name}</span>
        </div>
        <IconButton
         onClick={handleClick}
         size="small"
        
         
        >

          
            <Avatar sx={{background: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)', color:'#3C4043'}}>
              {getInitials(user.user?.first_name, user.user?.last_name)}
            </Avatar>
            
            


        </IconButton>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          
          {user?.user?.role === 1 ? (
              <MenuItem onClick={() => setShowDialogModal(true)}>
                <ListItemIcon>
                  <DomainRoundedIcon fontSize='small'/>
                </ListItemIcon>
                Trocar de organização
              </MenuItem>
            ) : (
              <div></div>
            )}
          
        
          <MenuItem onClick={handleLogoutButton}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sair
          </MenuItem>
          
        </Menu>
        
        <ChangeCompanyDialog show={showDialogModal} onClose={() => setShowDialogModal(false)} />
      </ContainerNameManagerHeader>


    </ContainerManagerHeader>
    
  )
};


export default HeaderManager;
