import requestBack from '../config/request-back'

const createCompany = async (authData: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/new-company`,
		data: authData,
		vtAuth: true
	})

	return response
}


const updateCompany = async (authData: any) => {
	let response = await requestBack({
		method: 'PUT',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/update-company`,
		data: authData,
		vtAuth: true,
	})

	return response
}


const getCompanyById = async (companyId: any) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/company-by-id/${companyId}`,
		vtAuth: true,
	})

	return response
}

const getAdminsByCompany = async (filter:any,page = 1,) => {
	let query = "?page=" + page 
    if (filter.company_id) {
        query += "&company_id=" + filter.company_id
    }
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/admins-by-company/${filter.company_id}`,
		vtAuth: true,
	})

	return response
}

const getAllCompanies = async () => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/all-companies`,
		vtAuth: true,
	})

	return response
}


export { createCompany, updateCompany, getCompanyById as getCompanyId, getAdminsByCompany, getAllCompanies }
