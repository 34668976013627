import React, { useEffect } from 'react'

import { useAuth } from '../../context/AuthContext'
import { useToast } from '../../context/Toast'
import { useQuestions } from '../../context/QuestionContext'
interface RouteMiddlewareProps {
	permissions?: string[]
	element: any
}


const QuestionMiddleware: React.FC<RouteMiddlewareProps> = ({
	permissions,
	element
}) => {

	const authUser = localStorage.getItem('zenbox.identity');
    const hasZenboxVerify = localStorage.getItem('zenbox.verify');
    const currentPath = window.location.pathname;

    if (!hasZenboxVerify) {
        return  window.location.href = '/signup';
        
    }

    //todo verificar o codigo de acesso, se exisitir 
	

    // if (authUser && !JSON.parse(authUser).user?.id && currentPath !== '/register') {
       
    //     return  window.location.href = '/register';
    // }

    return element;

}

export default QuestionMiddleware


