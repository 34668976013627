import { Col, Container, Row, Spinner } from "react-bootstrap";
import Header from "../../components/header"
import { BackButtonSignUp, ContainerSignUp, ContainerSignUpButtons, NextButtonSignUp, StyledInputSignUp, StyledSignUpText, StyledSignUpTextAdmin } from "../../components/renderField/styles";
import { CardEmployee } from "../welcome/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { StyledBackButton, StyledBackButtonAdmin, StyledContainerButtons, StyledContainerLine, StyledContainerLineAdmin, StyledContainerParagraph, StyledLoginButton, StyledParagraph, StyledParagraphAdmin, StyledSwitchButton, StyledSwitchButtonAdmin } from "../signup/styles";
import { useToast } from "../../context/Toast";
import CustomCard from "../../components/customCard";
import PhoneInput from 'react-phone-number-input'
import ptBr from 'react-phone-number-input/locale/pt-BR.json'
import { StyledInputAdmin } from "./inputStyle";
import 'react-phone-number-input/style.css'


const CellphoneAuthAdmin : React.FC = () =>{

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const {showToast} = useToast()

    const [phone, setPhone] = useState<any>(''); // Tipando o useState para string
    const [error, setError] = useState<string>(''); // Tipando o erro também como string

    // Função para validar o número de telefone
    const validatePhoneNumber = (): string | null => {
        if (!phone) {
            showToast('Você precisa informar um número de telefone', 'error')
            return null;
        }
        
        //adiciona o "+" ao DDI
        const formattedPhone = phone.startsWith('+') ? phone : `+${phone}`;
        

        return formattedPhone; //retorna o número formatado
    };
    

    // Função chamada no envio do formulário
    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        const formattedPhone = validatePhoneNumber();
    
        if (formattedPhone) {
            showToast(`Foi enviado um código no seu celular por SMS para o celular ${formattedPhone} `, 'success', 6000)
            navigate('/admin/confirmation-code-auth')
        }
    };
    

   
    return(
        <>
            <Container fluid className="d-flex h-100 mt-5 mb-2">
                <Row className="justify-content-center align-self-center w-100">
                    <Col md={9} lg={7}>
                        <CustomCard>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{
                                    minHeight: '300px',

                                }}>
                                    <Spinner style={{ color: '#c9c9c9' }}></Spinner>
                                </div>
                            ) : (
                                <ContainerSignUp>
                                    <div className="d-flex justify-content-center flex-column ">
                                        <StyledSignUpTextAdmin>{'Qual é o seu numero de celular?'}</StyledSignUpTextAdmin>
                                        <div className="d-flex justify-content-center" 
                                            style={{
                                                width: '100%',
                                                flexDirection: 'column',
                                            }}>
                                        
                                            {/* <PhoneNumberInput 
                                                styleAdmin='admin'
                                                phone={phone}
                                                setPhone={setPhone} 
                                        
                                            /> */}

                                            <StyledInputAdmin
                                                placeholder="Digite seu número de telefone"
                                                value={phone}
                                                onChange={setPhone}
                                                limitMaxLength={true}
                                                labels={ptBr}  
                                                defaultCountry="BR"
                                                className="input-admin"                         
                                            />
                                            {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}

                                        </div>
                                    </div>
                                    <StyledContainerParagraph>
                                        <StyledContainerLineAdmin></StyledContainerLineAdmin>
                                        <StyledParagraphAdmin>ou</StyledParagraphAdmin>
                                        <StyledContainerLineAdmin></StyledContainerLineAdmin>
                                    </StyledContainerParagraph>
                                    
                                    <StyledSwitchButtonAdmin onClick={() => navigate('/admin/signup')}>Fazer login utilizando o endereço de e-mail</StyledSwitchButtonAdmin>
                                    <ContainerSignUpButtons>
                                        <StyledContainerButtons>
                                            <StyledBackButtonAdmin onClick={() => { navigate('/admin/signup') }} >Voltar</StyledBackButtonAdmin>
                                            
                                            <StyledLoginButton onClick={handleSubmit}> Fazer Login</StyledLoginButton>
                                        </StyledContainerButtons>
                                        
                                    </ContainerSignUpButtons>
                                </ContainerSignUp>
                            )}
                        </CustomCard>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CellphoneAuthAdmin;