import { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import Welcome from '../pages/welcome'
import SignUp from '../pages/signup'
import UserQuestions from '../pages/userQuestions'
import QuestionMiddleware from './components/QuestionMiddleware'
import UserRegister from '../pages/userRegister'
import PrivateRoutes from './PrivateRoutes'
import PrivateMiddleware from './components/PrivateMiddleware'
import RoleMiddleware from './components/RoleMiddleware'
import SignUpAdmin from '../pages/signup/signupAdmin'
import { Col } from 'react-bootstrap'
import styled, { ThemeProvider } from 'styled-components';
import {createGlobalStyle} from 'styled-components'
import AutoSubscriptionMiddleware from './components/AutoSubscriptionMiddleware'
import { log } from 'util'
import SignUpWebSummmitRio24 from '../pages/signup/signupWebSummitRio24'
import WelcomeWebSummit from '../pages/welcome/welcomeWebSummit'
import CellphoneAuth from '../pages/cellphoneAuth/cellphoneAuthEmployee'
import ConfirmationCodeAuth from '../pages/confirmationCode/confirmationCodeAuthEmployee'
import CellphoneAuthAdmin from '../pages/cellphoneAuth/cellphoneAuthAdmin'
import ConfirmationCodeAuthAdmin from '../pages/confirmationCode/confirmationCodeAuthAdmin'

interface LayoutProps {
	children: ReactNode;
}

interface Theme {
	colorForLargeSize: string;
	colorForSmallSize: string;
}

const themes: Record<string, Theme> = {
	'/signup': {
	  colorForLargeSize: '#2A2A48',
	  colorForSmallSize: '#373956',
	},
	'/welcome': {
	  colorForLargeSize: '#2A2A48',
	  colorForSmallSize: '#373956',
	},
	'/register': {
		colorForLargeSize: '#2A2A48',
		colorForSmallSize: '#373956',
	},
	'/admin': {
		colorForLargeSize: '#F5F7F8',
		colorForSmallSize: '#F5F7F8',
	},
	'/admin/signup': {
		colorForLargeSize: '#F5F7F8',
		colorForSmallSize: '#FFFFFF',
	},
	// Adicione mais rotas conforme necessário
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colorForLargeSize};
    @media (max-width: 694px) {
      background-color: ${(props) => props.theme.colorForSmallSize};
    }
  }
`;



const UserRoutes: FC = function UserRoutes() {


	const location = useLocation();
  	const [isAdminPage, setIsAdminPage] = useState(false);
	const [isAutoSub, setIsAutoSub] = useState(false);

	useEffect(() => {
		setIsAdminPage(location.pathname.startsWith('/admin'));
	}, [location.pathname]);

  	const theme = isAdminPage ? themes['/admin/signup'] : themes[location.pathname] || themes['/welcome'];
  
	const Layout: React.FC<LayoutProps> = ({ children }) => {
		return (
			<ThemeProvider theme={theme}>
			  <GlobalStyle/>
				{children}
			  
			</ThemeProvider>
		);
	};
	
	return (
		<>
			<Routes>
				{/* <Route path='login' element={<Login />} /> */}
				<Route path='welcome' element={<Layout> <Welcome/> </Layout>} />

                <Route path='signup' element={<Layout> <SignUp /> </Layout>} />

				<Route path='cellphone-auth' element ={<Layout> <CellphoneAuth /> </Layout>} />

				<Route path='confirmation-code-auth' element ={<Layout> <ConfirmationCodeAuth/> </Layout>} />

				<Route path='register' element={<QuestionMiddleware element={<Layout><UserRegister /></Layout>} permissions={['']} />} />
					
				<Route path='questions' element={<QuestionMiddleware element={<Layout><UserQuestions /></Layout>} permissions={['']} />} />

				{/* ADMIN ROUTES */}
				<Route path='admin/signup' element={<Layout><SignUpAdmin /></Layout>}  />

				<Route path='admin/cellphone-auth' element={<Layout> <CellphoneAuthAdmin /> </Layout>}  />

				<Route path='admin/confirmation-code-auth' element={<Layout> <ConfirmationCodeAuthAdmin /> </Layout>}  />
					
				<Route path='/admin' element={<Navigate to='insights' replace />} />
					
				<Route path='/admin/*'element={<PrivateMiddleware element={<PrivateRoutes />} permissions={['']} />} />

				<Route  path='*' element={<Navigate to='welcome' replace />} />

				<Route
					path='/'
					element={
						<AutoSubscriptionMiddleware isAutoSub={setIsAutoSub}
							render={() => {
								return isAutoSub 
									? <Layout><WelcomeWebSummit/></Layout> 
									: <Layout><Welcome/></Layout>;
							}}
						/>
					}
				/>

				<Route path='self-signup' element={<Layout> <SignUpWebSummmitRio24 /> </Layout>} />

			</Routes>
		</>
	)

	
}

export default UserRoutes
