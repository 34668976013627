import { FC, useContext, useEffect } from 'react'
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom'

import Insights from '../pages/insights'
import Sidebar from '../components/sideBar'
import Settings from '../pages/settings'
import HappinessReport from '../pages/happinessReport'




const PrivateRoutes: FC = function PrivateRoutes() {

    return (
        <>
            <Sidebar>
                <Routes>
                <Route path='/' element={<Insights />} />
                <Route path='insights' element={<Insights />} />
                    <Route path='calendario' element={<></>} />
                    <Route path='sustentabilidade' element={<></>} />
                    <Route path='relatorio' element={<HappinessReport/>} />
                    <Route path='configuracoes' element={<Settings />} />
                    {/* <Route path='*' element={<Navigate to='/insights' replace />} /> */}
                </Routes>
            </Sidebar>
        </>

    )
}

export default PrivateRoutes


