import React, { useEffect, useState } from 'react'


import { getUserByEmail, getUserById } from '../../services/user/user'
import { UserRole } from '../../services/utils/enum'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useToast } from '../../context/Toast';
import { useAuth } from '../../context/AuthContext'

interface RouteMiddlewareProps {
    permissions?: string[]
    element: any
}


const PrivateMiddleware: React.FC<RouteMiddlewareProps> = ({ element }) => {
    const [hasRelease, setHasRelease] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()
    const { showToast } = useToast()
    const { saveDataVerifyOnStorage, saveDataSignUpOnStorage, SignOut } = useAuth()
    useEffect(() => {
        const getTokenFromUrl = async () => {
            const queryParams = new URLSearchParams(location.search);
            const url: any = {};
            if (queryParams.get('token')) url.verifyToken = queryParams.get('token');
            if (queryParams.get('email')) url.user = {
                email: queryParams.get('email'),
            };
            if (Object.keys(url).length !== 0) {
                saveDataVerifyOnStorage(url);
                if (queryParams.get('email')) {
                    let result = null;
                    try {
                        result = await getUserByEmail(queryParams.get('email'));
                    } catch (error) {
                        console.warn(error);
                    }
                    const dataToSave = {
                        id: result?.id,
                        email: result?.email,
                        status: result?.status,
                        first_name: result?.first_name,
                        last_name: result?.last_name,
                        company_id: result?.company?.Item?.id,
                        company_name: result?.company?.Item?.name_company,
                        role: result?.role,
                    };
                    saveDataSignUpOnStorage(dataToSave);
                    await checkPermissions();
                } else {
                    await checkPermissions();
                }
            } else {
                await checkPermissions();
            }
        };

        const checkPermissions = async () => {
            const authUser = localStorage.getItem('zenbox.identity');
            const user = authUser ? JSON.parse(authUser) : null;
            try {
                if (!user?.user?.id) {
                    showToast('Faça login com uma conta de gestor para acessar essa página', 'info', 5000);
                    navigate('/admin/signup');
                    
                } else {
                    const result = await getUserById(user?.user?.id);
                    if (result && result.role && result.role !== UserRole.employee) {
                        setHasRelease(true);
                    } else {
                        showToast('Você não tem permissão para acessar essa página.', 'error', 5000);
                        navigate('/admin/signup');
                    }
                }
               
            } catch (error: any) {
                if (error?.response?.data?.error) {
                    switch(error.response.data.error) {
                      case "Erro ao validar o token - zenbox_backend":
                        showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                        setTimeout( () => {
                            showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                            setTimeout( () => {                                
                                SignOut('/admin/signup');
                            }, 5000);
                        }, 2500);
                        break;
                        
                      default:
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                        throw error;
                    }
                
                } else {
                    showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                    throw error
                }              
            }
        };

        getTokenFromUrl();
    }, [navigate]);

    return hasRelease ? element : null;
    // return element
};

export default PrivateMiddleware


