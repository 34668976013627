import { Row } from "react-bootstrap";
import { Input } from "reactstrap";
import styled from "styled-components";


export const ContainerDashboard = styled.div`
    padding: 0 2em;
    @media(max-width: 768px){
        padding: 0;
    }

`;

export const ContainerSelectFilters = styled.div`
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media(max-width: 694px){
        padding: 1em 0;
        flex-direction: column;
        gap: 1em;
    }

    @media(min-width: 695px) and (max-width: 768px){
        padding: 1em 0;
        gap: 0;
    }

`;

export const ContainerFilters = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    width: 49.3%;
    color: #3C4043;
    @media(max-width: 694px){
        flex-direction: column;
        width: 100%;
        gap: 1em;
    }

`
export const RowGridCharts = styled(Row)`
    display: grid;
    gap: 1em;
    padding-left:calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x)* .5);

    @media(max-width: 1000px){
        grid-template-columns: 1fr;
    }

    @media(min-width: 1001px) and (max-width: 1200px){
        grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 1201px){
        grid-template-columns: 1fr 1fr 1fr;
    }

`;
export const StyledSelectMonth = styled(Input)`
    background:  #FFF;
    color: #3C4043;
    border: none;
    width: 70%;
    padding: .88em;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    text-transform: capitalize;
    font-weight: 700;
    cursor: pointer;

    @media(max-width: 694px){
        background: #FFF;
        width: 90%;
        text-align: center;
        
    }
`

export const StyledCardIndicators = styled.div`
    display: grid;
    gap: 2em;

    @media(max-width: 325px){
        grid-template-columns: 1fr;
    }

    @media (min-width: 326px) and (max-width: 599px){
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }

    @media(min-width: 600px) and (max-width: 1291px){
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2.5em;
    }

    @media(min-width: 1292px){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;    
       
    }   
`;

export const ContainerFilterPeriod = styled.div`
    display: flex; 
    align-items: center; 
    gap: 1em;
    width: 30%;
    @media (max-width: 694px){
        width: 100%;
    }
    @media (min-width: 695px) and (max-width: 1200px){
        width: 30%;
    }
    @media (min-width: 1201px) and (max-width: 1440px){
        width: 23%;
    }
    @media (min-width: 1441px){
        width: 18%;
    }

`;