import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import QuestionCard from "../../components/questionCard";
import { CardInicio } from "../welcome/styles";


const UserQuestions: React.FC = () => {
  return (

    <>
      <Header  />
    
      <Container fluid className="d-flex h-100 mt-5 mb-2">
        <Row className="justify-content-center align-self-center w-100">
          <Col md={9} lg={7}> 
            <QuestionCard></QuestionCard>
          </Col>
        </Row>
      </Container>
      
    
    </>








  );
};

export default UserQuestions;
